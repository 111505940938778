body {
  color: #5e6e82;
  text-align: left;
  background-color: #edf2f9;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid #edf2f9;
  border-radius: 0.375rem;
  -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%),
    0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.bg-light {
  background-color: #f9fafd !important;
}
.bg-200 {
  background-color: #edf2f9 !important;
}
.card-header {
  padding: 1rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid #edf2f9;
  font-weight: 600;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.dropdown-menu {
  font-size: 0.83333rem;
  overflow: hidden;
  border: 0px;
  border-radius: 0.375rem;
  -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%),
    0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #edf2f9;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #344050;
  text-decoration: none;
  background-color: #edf2f9;
}

.modal-open {
  overflow: hidden;
}

.bg-gradient-primary {
  background: #3699ff !important;
  background: linear-gradient(45deg, #007af7 0%, #3699ff 100%) !important;
  border-color: #3699ff !important;
}
.bg-gradient-info {
  background: #0dcaf0 !important;
  background: linear-gradient(45deg, #00d4ff 0%, #0dcaf0 100%) !important;
  border-color: #0dcaf0 !important;
}
.bg-gradient-warning {
  background: #ffc107 !important;
  background: linear-gradient(45deg, #f9b115 0%, #ffc107 100%) !important;
  border-color: #ffc107 !important;
}
.bg-gradient-danger {
  background: #dc3545 !important;
  background: linear-gradient(45deg, #e55353 0%, #dc3545 100%) !important;
  border-color: #dc3545 !important;
}
.progress-xs {
  height: 4px;
}
.font-size-lg {
  font-size: 1.08rem;
}
.font-size-sm {
  font-size: 0.8rem;
}
.text-dark-75 {
  color: #3f4254 !important;
}
.text-dark-25 {
  color: #7d7d7d !important;
}
.text-link {
  color: #007af7;
  text-decoration: underline;
}
.text-value-lg {
  font-size: 1.3rem;
}
.font-weight-bolder {
  font-weight: 600 !important;
}
.icon-xl {
  font-size: 1.75rem !important;
}
.cursor-pointer {
  cursor: pointer;
}

.a-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #e9ecef99;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-pre-line{
  white-space: pre-line;
}

@-webkit-keyframes hvr-ripple-out {
  50% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
@keyframes hvr-ripple-out {
  50% {
    top: -12px;
    right: -12px;
    bottom: -12px;
    left: -12px;
    opacity: 0;
  }
  100%{
    opacity: 0;
  }
}
.ani-hvr-ripple-out{
  position: relative;
}
.ani-hvr-ripple-out:before {
  content: '';
  position: absolute;
  border: #ff890085 solid 2px;
  border-radius: 4px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* animation-duration: 1s; */
  -moz-animation: hvr-ripple-out 2s 0s infinite;
  -webkit-animation: hvr-ripple-out 2s 0s infinite;
  animation: hvr-ripple-out 2s 0s infinite;
}

@-webkit-keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}
@keyframes hvr-buzz {
  50% {
    -webkit-transform: translateX(3px) rotate(2deg);
    transform: translateX(3px) rotate(2deg);
  }
  100% {
    -webkit-transform: translateX(-3px) rotate(-2deg);
    transform: translateX(-3px) rotate(-2deg);
  }
}

.ani-hvr-buzz{
  position: relative;
  -moz-animation: hvr-buzz 0.15s 10s infinite;
  -webkit-animation: hvr-buzz 0.15s 10s infinite;
  animation: hvr-buzz 0.15s 10s infinite;
}