@font-face {
    font-family: "flaticon";
    src: url("./flaticon.ttf?95aba2b85f3f21f02517bd3d10f4daa4") format("truetype");
/* url("./flaticon.woff?95aba2b85f3f21f02517bd3d10f4daa4") format("woff"),
url("./flaticon.woff2?95aba2b85f3f21f02517bd3d10f4daa4") format("woff2"),
url("./flaticon.eot?95aba2b85f3f21f02517bd3d10f4daa4#iefix") format("embedded-opentype"),
url("./flaticon.svg?95aba2b85f3f21f02517bd3d10f4daa4#flaticon") format("svg"); */
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-menu:before {
    content: "\f101";
}
.flaticon-user:before {
    content: "\f102";
}
.flaticon-navigation:before {
    content: "\f103";
}
.flaticon-arrow-down-sign-to-navigate:before {
    content: "\f104";
}
.flaticon-search:before {
    content: "\f105";
}
.flaticon-phone-message:before {
    content: "\f106";
}
.flaticon-envelope:before {
    content: "\f107";
}
.flaticon-chat:before {
    content: "\f108";
}
.flaticon-phone-call:before {
    content: "\f109";
}
.flaticon-facebook:before {
    content: "\f10a";
}
.flaticon-instagram:before {
    content: "\f10b";
}
.flaticon-arrow-left:before {
    content: "\f10c";
}
.flaticon-secured-lock:before {
    content: "\f10d";
}
.flaticon-check:before {
    content: "\f10e";
}
.flaticon-close:before {
    content: "\f10f";
}
.flaticon-fingerprint:before {
    content: "\f110";
}
.flaticon-bus-stop:before {
    content: "\f111";
}
.flaticon-airport:before {
    content: "\f112";
}
.flaticon-skyline:before {
    content: "\f113";
}
.flaticon-history:before {
    content: "\f114";
}
.flaticon-play-button:before {
    content: "\f115";
}
.flaticon-check-1:before {
    content: "\f116";
}
.flaticon-lock-button:before {
    content: "\f117";
}
.flaticon-right-arrow:before {
    content: "\f118";
}
.flaticon-left-arrow:before {
    content: "\f119";
}
.flaticon-filter:before {
    content: "\f11a";
}
.flaticon-square:before {
    content: "\f11b";
}
.flaticon-protection:before {
    content: "\f11c";
}
.flaticon-passport:before {
    content: "\f11d";
}
.flaticon-fast:before {
    content: "\f11e";
}
.flaticon-star:before {
    content: "\f11f";
}
.flaticon-radio-button:before {
    content: "\f120";
}
.flaticon-radio-button-1:before {
    content: "\f121";
}
.flaticon-unchecked:before {
    content: "\f122";
}
.flaticon-approved:before {
    content: "\f123";
}
.flaticon-safety-seat:before {
    content: "\f124";
}
.flaticon-distance:before {
    content: "\f125";
}
.flaticon-gearbox:before {
    content: "\f126";
}
.flaticon-fuel-station:before {
    content: "\f127";
}
.flaticon-security-camera:before {
    content: "\f128";
}
.flaticon-bluetooth:before {
    content: "\f129";
}
.flaticon-map:before {
    content: "\f12a";
}
.flaticon-car:before {
    content: "\f12b";
}
.flaticon-camera:before {
    content: "\f12c";
}
.flaticon-solar-energy-car:before {
    content: "\f12d";
}
.flaticon-auto-parking:before {
    content: "\f12e";
}
.flaticon-car-insurance:before {
    content: "\f12f";
}
.flaticon-usb-drive:before {
    content: "\f130";
}
.flaticon-id-card:before {
    content: "\f131";
}
.flaticon-clipboard:before {
    content: "\f132";
}
.flaticon-note:before {
    content: "\f133";
}
.flaticon-id-card-1:before {
    content: "\f134";
}
.flaticon-certificate:before {
    content: "\f135";
}
.flaticon-next:before {
    content: "\f136";
}
.flaticon-back:before {
    content: "\f137";
}
.flaticon-fuel:before {
    content: "\f138";
}
.flaticon-wifi:before {
    content: "\f139";
}
.flaticon-scooter:before {
    content: "\f13a";
}
.flaticon-location:before {
    content: "\f13b";
}
.flaticon-offer:before {
    content: "\f13c";
}
.flaticon-meter:before {
    content: "\f13d";
}
.flaticon-information:before {
    content: "\f13e";
}
.flaticon-add:before {
    content: "\f13f";
}
.flaticon-copy:before {
    content: "\f140";
}
.flaticon-check-2:before {
    content: "\f141";
}
.flaticon-electric-car:before {
    content: "\f142";
}
.flaticon-dots-menu:before {
    content: "\f143";
}
.flaticon-circle:before {
    content: "\f144";
}
.flaticon-booking:before {
    content: "\f145";
}
.flaticon-clock:before {
    content: "\f146";
}
.flaticon-cashless-payment:before {
    content: "\f147";
}
.flaticon-car-rental:before {
    content: "\f148";
}
.flaticon-car-1:before {
    content: "\f149";
}
.flaticon-rating:before {
    content: "\f14a";
}
.flaticon-star-1:before {
    content: "\f14b";
}
.flaticon-redo-arrow-symbol:before {
    content: "\f14c";
}
.flaticon-car-2:before {
    content: "\f14d";
}
.flaticon-denied:before {
    content: "\f14e";
}
.flaticon-close-1:before {
    content: "\f14f";
}
.flaticon-shield:before {
    content: "\f150";
}
.flaticon-wallet:before {
    content: "\f151";
}
.flaticon-go:before {
    content: "\f152";
}
.flaticon-steering-wheel:before {
    content: "\f153";
}
.flaticon-comment:before {
    content: "\f154";
}
.flaticon-credit-card-payment:before {
    content: "\f155";
}
.flaticon-profits:before {
    content: "\f156";
}
.flaticon-credit-card:before {
    content: "\f157";
}
.flaticon-bank:before {
    content: "\f158";
}
.flaticon-grocery-store:before {
    content: "\f159";
}
.flaticon-credit-card-1:before {
    content: "\f15a";
}
.flaticon-car-3:before {
    content: "\f15b";
}
.flaticon-dollar-symbol:before {
    content: "\f15c";
}
.flaticon-work-time:before {
    content: "\f15d";
}
.flaticon-search-engine:before {
    content: "\f15e";
}
.flaticon-diskette:before {
    content: "\f15f";
}
.flaticon-shield-1:before {
    content: "\f160";
}
.flaticon-car-4:before {
    content: "\f161";
}
.flaticon-image:before {
    content: "\f162";
}
.flaticon-image-1:before {
    content: "\f163";
}
.flaticon-file:before {
    content: "\f164";
}
.flaticon-investment:before {
    content: "\f165";
}
.flaticon-calendar:before {
    content: "\f166";
}
.flaticon-upload-big-arrow:before {
    content: "\f167";
}
.flaticon-resume:before {
    content: "\f168";
}
.flaticon-house:before {
    content: "\f169";
}
.flaticon-car-5:before {
    content: "\f16a";
}
.flaticon-credit-card-2:before {
    content: "\f16b";
}
.flaticon-mail:before {
    content: "\f16c";
}
.flaticon-user-1:before {
    content: "\f16d";
}
.flaticon-two-ways:before {
    content: "\f16e";
}
.flaticon-thunder:before {
    content: "\f16f";
}
.flaticon-dollar-symbol-1:before {
    content: "\f170";
}
.flaticon-gearbox-1:before {
    content: "\f171";
}
.flaticon-car-6:before {
    content: "\f172";
}
.flaticon-sedan:before {
    content: "\f173";
}
.flaticon-manual-transmission:before {
    content: "\f174";
}
.flaticon-lubricant:before {
    content: "\f175";
}
.flaticon-diesel:before {
    content: "\f176";
}
.flaticon-discount-voucher:before {
    content: "\f177";
}
.flaticon-rating-1:before {
    content: "\f178";
}
.flaticon-customer-service:before {
    content: "\f179";
}
.flaticon-languages:before {
    content: "\f17a";
}
.flaticon-share-ride:before {
    content: "\f17b";
}
.flaticon-research:before {
    content: "\f17c";
}
.flaticon-password:before {
    content: "\f17d";
}
.flaticon-padlock:before {
    content: "\f17e";
}
.flaticon-quotes:before {
    content: "\f17f";
}
.flaticon-gallery:before {
    content: "\f180";
}
.flaticon-switch-camera:before {
    content: "\f181";
}
.flaticon-photo:before {
    content: "\f182";
}
.flaticon-privacy:before {
    content: "\f183";
}
.flaticon-wedding-car:before {
    content: "\f184";
}
.flaticon-armchair:before {
    content: "\f185";
}
.flaticon-convenient:before {
    content: "\f186";
}
.flaticon-man:before {
    content: "\f187";
}
.flaticon-discount:before {
    content: "\f188";
}
.flaticon-stopwatch:before {
    content: "\f189";
}
.flaticon-verified:before {
    content: "\f18a";
}
.flaticon-pencil:before {
    content: "\f18b";
}
.flaticon-face-id:before {
    content: "\f18c";
}
.flaticon-qr-code:before {
    content: "\f18d";
}
.flaticon-mobile:before {
    content: "\f18e";
}
.flaticon-face-scanner:before {
    content: "\f18f";
}
.flaticon-electric:before {
    content: "\f190";
}
