.theme-sigo-responsive.theme-sigo {
  --mauxam: #868181;
  padding-top: 0px !important;
  & .c-wrapper {
    padding-bottom: 85px;
    &.hide-header {
      padding-bottom: 0;
    }
    &.pd-85px {
      padding-bottom: 85px;
    }
  }
  & .fs-14px {
    font-size: 14px;
  }
  & .btn-outline-black {
    border: 1px solid var(--mauden);
    background-color: #fff;
    font-weight: 600;
    &:hover,
    &:focus {
      background-color: var(--mauden);
      border: 1px solid var(--mauden);
      color: #fff;
    }
  }
  & .btn-outline-black-grey {
    border: 1px solid var(--mauxam);
    background-color: #fff;
    font-weight: 600;
    &:hover,
    &:focus {
      background-color: var(--mauden);
      border: 1px solid var(--mauden);
      color: #fff;
    }
  }
  & nav,
  & .navbar {
    display: none;
  }
  & .wrap-header {
    display: none;
  }
  & .c-header {
    z-index: 999;
  }
  & .page-user-profile {
    & .navbar-nav {
      z-index: 100;
    }
  }
  & .modal {
    & .modal-search-mobile {
      & .modal-body {
        padding: 0px !important;
        margin-bottom: 0;
      }
    }
    & .modal-select-date {
      height: 100%;
      padding-top: 0;
      & .modal-content {
        height: 100%;
      }
      & .modal-body {
        height: 100%;
        padding-bottom: 0 !important;
      }
    }
    & .modal-filter {
      &.scrollbar-right {
        & .modal-body {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
        & .mCSB_inside > .mCSB_container {
          margin-right: 0;
        }
      }
    }
  }
  & .modal-change-number-of-date {
    & .wrap-btn {
      justify-content: center;
      align-items: center;
    }
    & .btn-circle {
      padding: 5px;
      border: 1px solid #6a6a6a;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      text-align: center !important;
      display: inline-flex !important;
      align-items: center !important;
      justify-content: center !important;
      cursor: pointer;
      & svg {
        width: 12px;
        height: 12px;
        display: block;
        fill: #6a6a6a;
      }
      &:hover {
        border-color: var(--color-text-000);
        & svg {
          fill: var(--color-text-000);
        }
      }
    }
    input {
      width: 140px;
      font-size: 32px;
      color: var(--color-text-000);
      height: 48px;
      text-align: center;
      font-weight: 600;
      margin: 0 1.5rem;
    }
    & .footer {
      padding-top: 1.25rem;
      & .btn {
        width: 100%;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }
}
