.theme-v1 {
  --bs-background: #edf2f9;
}
.theme-v1 .table {
  --bs-table-striped-bg: rgba(0, 0, 0, 0.02);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.05);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgb(0 173 255 / 2%);
}
.theme-v1 .c-header {
  z-index: 5;
}
.theme-v1 .expand-cell-header {
  width: 30px;
}
.theme-v1 table.table.table-fixed {
  table-layout: fixed;
}
.theme-v1 table.table.table-fixed > * > * > * {
  overflow: hidden;
}
.theme-v1 table.table > thead > tr > th {
  white-space: nowrap;
  text-overflow: ellipsis;
}
// .theme-v1 main > header {
//   height: 70px;
// }
// .theme-v1 .content {
//   max-height: calc(100vh - 70px);
//   overflow: auto;
// }
// .theme-v1 main {
//   overflow: hidden !important;
// }
// .theme-v1 nav {
//   max-height: 100vh;
//   overflow: auto;
// }
*::-webkit-scrollbar {
  background: transparent;
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-track:hover {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #c9d1db;
  border-radius: 16px;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #c9d1db;
}

/* set button(top and bottom of the scrollbar) */
.theme-v1 nav::-webkit-scrollbar-button {
  display: none;
}

.theme-v1 .modal-body {
  background-color: var(--bs-background);
}
// @-webkit-keyframes jsuprow {
//   from {
//     background-color: #c8deff;
//   }
//   to {
//     background-color: inherit;
//   }
// }
// @-moz-keyframes jsuprow {
//   from {
//     background-color: #c8deff;
//   }
//   to {
//     background-color: inherit;
//   }
// }
// @-o-keyframes jsuprow {
//   from {
//     background-color: #c8deff;
//   }
//   to {
//     background-color: inherit;
//   }
// }
@keyframes jsuprow {
  0% {
    opacity: 0.5;
  }
  25% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}
.theme-v1 tr.jsuprow {
  -webkit-animation: jsuprow 2s 0s; /* Safari 4+ */
  -moz-animation: jsuprow 2s 0s; /* Fx 5+ */
  -o-animation: jsuprow 2s 0s; /* Opera 12+ */
  animation: jsuprow 2s 0s; /* IE 10+ */
}
.c-app {
  color: #3c4b64;
  background-color: #ebedef;
  --color: #3c4b64;
  --elevation-base-color: 60, 75, 100;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  min-height: 100vh;
}
.c-sidebar {
  overflow: hidden;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 0 0 256px;
  flex: 0 0 256px;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-order: -1;
  order: -1;
  width: 256px;
  padding: 0;
  box-shadow: none;
  color: #fff;
  background: #3c4b64;
  transition: box-shadow 0.3s 0.15s, margin-left 0.3s, margin-right 0.3s,
    width 0.3s, z-index 0s ease 0.3s, -webkit-transform 0.3s;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s,
    margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s;
  transition: box-shadow 0.3s 0.15s, transform 0.3s, margin-left 0.3s,
    margin-right 0.3s, width 0.3s, z-index 0s ease 0.3s, -webkit-transform 0.3s;
}
.c-sidebar.c-sidebar-fixed {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1030;
}
.c-sidebar.c-sidebar-overlaid {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1032;
}
.c-sidebar.c-sidebar-lg {
  -ms-flex: 0 0 320px;
  flex: 0 0 320px;
  width: 320px;
}

.c-sidebar.c-sidebar-fixed ~ .c-wrapper {
  margin-left: 256px;
}
.c-wrapper {
  transition: margin 0.3s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex: 1;
  flex: 1;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  min-height: 100vh;
}
.c-body {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.theme-v1.hide-footer .c-footer {
  display: none;
}
.theme-v1.fix-height {
  height: 100vh;
  overflow: hidden;
}
.theme-v1.fix-height #root {
  height: 100vh;
  overflow: hidden;
}
.c-footer {
  display: -ms-flexbox;
  display: flex;
  // -ms-flex: 0 0 50px;
  // flex: 0 0 50px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  padding: 0 1rem;
  color: #3c4b64;
  background: #ffffff;
  border-top: 1px solid #d8dbe0;
}
