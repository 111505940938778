// @import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat&family=Open+Sans&family=Roboto&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");
.btn-group-vertical > .btn-group:after,
.btn-group-vertical > .btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}
.btn-group-vertical > .btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.container-fluid > .navbar-collapse,
.container-fluid > .navbar-header,
.container > .navbar-collapse,
.container > .navbar-header {
  margin-right: -15px;
  margin-left: -15px;
}
.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  border-top: 1px solid transparent;
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}
.navbar-nav {
  margin: 7.5px -15px;
  display: block !important;
  & > li {
    & > a {
      padding-top: 10px;
      padding-bottom: 10px;
      line-height: 20px;
    }
  }
  & .dropdown-toggle {
    &::after {
      display: none;
    }
  }
}
.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  & > li {
    position: relative;
    display: block;
    & > a {
      position: relative;
      display: block;
      padding: 10px 15px;
      line-height: 20px;
    }
  }
}
.dropdown-menu {
  & > li > a {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: 400;
    line-height: 1.42857143;
    color: #333;
    white-space: nowrap;
    &:focus,
    &:hover {
      color: #262626;
      text-decoration: none;
      background-color: #f5f5f5;
    }
  }
  & .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
}
.navbar-nav > li > .dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
/* button*/
.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  & .caret {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid\9;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
  }
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  &:checked,
  &.is-active,
  &:focus,
  &:active {
    border: 1px solid #e6e6e6 !important;
    color: #333 !important;
    background-color: #e6e6e6 !important;
  }
  &:hover {
    background-color: #e6e6e6 !important;
  }
}
.btn-block {
  display: block;
  width: 100%;
}
.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary {
  background-image: none;
}
.btn-main {
  background-color: var(--mau1);
  border-color: var(--mau2);
  color: #fff;
}
.btn-main:hover,
.btn-main:active,
.btn-main:focus {
  background-color: #31b0d5;
  border-color: #269abc;
}
.btn-main:active,
.btn-main:focus {
  border: 1px solid transparent !important;
  box-shadow: none;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}
/**/
.mCustomScrollBox {
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr;
}
.mCSB_inside > .mCSB_container {
  margin-right: 30px;
}
.mCSB_container {
  overflow: hidden;
  width: auto;
  height: auto;
}
.small,
small {
  font-size: 85%;
}
hr {
  box-sizing: content-box;
  margin-top: 20px !important;
  border: 0;
  border-top: 1px solid #eee;
  background-color: #eee;
  height: 0 !important;
  opacity: 1 !important;
}
.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}
.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}
.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #ddd;
  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.breadcrumb > li + li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}
.theme-sigo {
  --mauden: #000;
  --bs-background: #fff;
  --color-text-info: #3366ff;
  --color-text-000: #000000;
  --color-divider: #e8e5e5;
  --color-border-control: #d4d4d4;
  --color-text-fff: #fff;
  --color-divider: #dfdfdf;
  --color-border-btn: #d2d2d2;
  --color-ic-back: #848484;
  --color-bg-black: #222222;
  --color-error: #c13817;
  --color-ic-show-pwd: #9b9b9b;
  --color-bg-inrange-calendar: #f1eeee;
  --color-border-c0: #c0c0c0;
  --color-divider-line: #e6e6e6;
  --color-border-e4: #e4e4e4;
  --color-text-b3: #b3b3b3;
  --color-text-search-bar: #535151;
  --color-text-363636: #363636;
  --color-border-blue-light: #97bbff;
  --color-bg-blue-light: #cfdefa;
  --color-select-active: #eaeaea;
  --color-select-des: #767676;
  --color-border-check-icon: #d8d8d8;
  --color-radio-check-icon: #797878;
  --color-text-595959: #595959;
  --color-text-d9d9d9: #d9d9d9;
  --color-text-5c5c5c: #5c5c5c;
  --color-text-e1e1e1: #e1e1e1;
  --color-text-222222: #222222;
  --color-text-686666: #686666;
  --color-text-6a6666: #6a6666;
  --color-text-747171: #747171;
  --color-border-d7d7d7: #d7d7d7;
  --color-border-d4d4d4: #d4d4d4;
  --color-border-dfdfdf: #dfdfdf;
  --color-border-c5c5Cc5: #c5c5c5;
  --color-border-d3d3d3: #d3d3d3;
  --color-warning-red: #c13615;
  --color-bg-warning: #fff8f5;
  --color-e7e7e7: #e7e7e7;
  --color-d9d9d9: #d9d9d9;
  --color-535353: #535353;
  --mautrang: #fff;
  font-family: "Manrope", sans-serif;
  & .mauden {
    color: var(--mauden);
  }
  & .c-wrapper {
    position: relative;
  }
  .modal {
    *::-webkit-scrollbar-thumb {
      background-color: #c9d1db !important;
      border-radius: 16px;
    }
    *::-webkit-scrollbar-thumb:hover {
      background-color: #c9d1db !important;
    }
    & .modal-default {
      & .modal-footer {
        padding: 12px 35px;
        & .btn {
          flex: 1;
          margin-right: 20px;
          margin-left: 0px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    & .modal-add-bank-account,
    & .modal-withdraw {
      & .modal-header {
        h5 {
          font-size: 19px;
        }
      }
    }
  }
  & .Toastify__toast-container {
    z-index: 9999 !important;
  }
  & .btn {
    box-shadow: none !important;
  }
  & .btn-primary {
    color: #fff;
    background-color: #276ef1;
    background-image: none;
    border: 1px solid #276ef1 !important;
  }
  & .btn-link {
    color: var(--mau1);
    font-size: 1rem;
    text-decoration: none;
    padding: 0;
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
    &:hover,
    &:focus {
      color: #000;
    }
  }
  & .text-danger {
    color: red !important;
  }
  & .c-app {
    background-color: var(--bs-background);
  }
  & .c-body {
    padding: 0 !important;
  }
  &.home {
    & .navbar {
      background-color: transparent;
      &.truot {
        background-color: #fff;
      }
      & .line {
        display: block;
      }
    }
  }
  &.category {
    padding-top: 0 !important;
    & .navbar .line {
      display: none;
    }
    & .rc-tooltip {
      padding-top: 0px;
    }
    & .rc-tooltip-arrow {
      display: none;
    }
    & input[type="checkbox"] {
      display: none;
    }
  }
  & .custom-header-modal {
    position: relative;
    margin: -25px -25px 0;
    padding: 25px;
    border-bottom: 1px solid var(--color-divider);
    & .title {
      text-align: center;
      font-size: 18px;
      font-weight: 700;
      color: var(--color-text-000);
    }
    & .btn-hide-modal {
      position: absolute;
      background-color: transparent;
      color: var(--color-text-000);
      box-shadow: none;
      font-size: 20px;
      padding: 0;
      top: 21px;
      border: none;
    }
    & .btn-hide-modal:hover {
      color: var(--color-text-000);
      box-shadow: none;
      background-color: transparent;
    }
  }
  & .modal {
    & .close {
      background-color: #fff;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      line-height: 35px;
      text-align: center;
      font-size: 20px;
      position: absolute;
      top: 0;
      right: 0;
      box-shadow: 0px 0px 5px #888;
      opacity: 1;
      cursor: pointer;
      z-index: 999;
      padding: 0;
    }
    color: #555;
    & .modal-body {
      border-radius: 20px;
    }
    & .footer-space-between {
      & .modal-footer {
        justify-content: space-between;
        &::before,
        &::after {
          display: none;
        }
      }
    }
    & .footer-btn-ok-full {
      & .btn-primary {
        width: 100%;
      }
    }
    & .modal-filter {
      &.filter-header-category {
        color: #666;
        & .btn-primary {
          width: 50%;
        }
      }
      &.scrollbar-right {
        & .mCSB_inside > .mCSB_container {
          margin-right: 10px;
          padding-right: 15px;
          padding-left: 15px;
        }
        & .modal-body {
          padding-left: 20px;
          padding-right: 0px;
        }
      }
      & .modal-header {
        padding: 25px 15px;
        & .modal-title {
          font-size: 20px;
          font-weight: bold;
        }
        & .close {
          right: unset;
          left: 15px;
          box-shadow: none;
          top: 38px;
          font-size: 22px !important;
          font-weight: 500;
          color: #4f4f4f;
          & span {
            // display: none;
            font-size: 35px;
            color: var(--color-text-000);
            font-weight: 400;
          }
          // &::before {
          //   content: "X";
          // }
        }
      }
      & .modal-body {
        & .mb-3 {
          margin-bottom: 0 !important;
        }
      }
      & .filter-category {
        & .label-control {
          margin-bottom: 0 !important;
          color: var(--color-text-000);
        }
        & .sub-label-control {
          margin-top: 2px;
          font-size: 14px;
          color: #7c7575;
          margin-bottom: 15px;
        }
        & .form-group {
          padding-bottom: 16px;
          border-bottom: 1px solid #e4e4e4;
          margin-bottom: 16px !important;
        }
        & .form-group.control-lastest {
          padding-bottom: 0 !important;
          border-bottom: none;
          margin-bottom: 0 !important;
        }
      }
      & .modal-footer {
        padding: 20px 35px;
        & .btn-secondary {
          background-color: transparent !important;
          border-color: transparent !important;
          color: #000 !important;
          font-weight: 700;
          font-size: 16px;
          text-decoration: underline;
          padding: 0;
          &:focus {
            box-shadow: none !important;
          }
        }
        & .btn-primary {
          font-size: 16px;
          font-weight: 500;
          padding-block: 10px;
          margin: 0;
        }
      }
    }
    & .modal-dialog-scrollable-new {
      height: calc(100% - 30px - 0.5rem);
      padding-top: 0;
      padding-bottom: 30px;
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      &.default {
        max-width: 850px;
      }
      &.modal_time_from_to {
        min-width: 600px;
        width: unset;
      }
      & .modal-body {
        padding-right: 20px;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        overflow: hidden;
      }
    }
    & .modal-header-left {
      & .modal-header {
        justify-content: flex-start;
      }
    }
  }
  & .form-control {
    &:focus {
      box-shadow: none;
    }
  }
  & .input-group {
    & .hienpass {
      z-index: 3;
    }
  }
  & .mCustomScrollbar {
    touch-action: pinch-zoom;
  }
  & .top-neg-25px {
    top: -25px !important;
  }
  & .left-0 {
    left: 0px !important;
  }
  & .max-height-440px {
    max-height: 440px !important;
  }
  & .max-height-400px {
    max-height: 400px !important;
  }
  & .max-height-330px {
    max-height: 330px !important;
  }
  & .max-height-category {
    //200 trên dưới 167 gồm header và bottom của form
    max-height: calc(100vh - 367px);
  }
  & .react-datepicker__triangle {
    display: none;
  }
  & .react-datepicker {
    font-size: 14px !important;
    border: 1px solid #c5c5c5;
    width: 21em;
    border-radius: 10px;
    box-shadow: 0px 0px 6px #aaa;
    background: #fff;
    color: #333;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0.2em 0.2em 0;
    & .react-datepicker__current-month,
    & .react-datepicker-time__header,
    & .react-datepicker-year-header {
      color: #333;
      font-weight: 700;
      font-size: 15px;
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }
    & .react-datepicker__month-container {
      width: 100%;
    }
    & .react-datepicker__header {
      border-bottom: 0;
      background-color: #fff;
    }
    & .react-datepicker__day-names {
      display: flex;
      align-items: flex-end;
      & .react-datepicker__day-name {
        padding: 0px 0px 5px 0px;
        font-size: 13px;
        text-align: center;
        font-weight: bold;
        border: 0;
        flex: 1;
        margin: 0;
      }
    }
    & .react-datepicker__month {
      margin: 0 0 0.2rem 0;
      border-radius: 0px 0px 12px 12px;
      overflow: hidden;
    }
    & .react-datepicker__week {
      display: flex;
      & .react-datepicker__day {
        border: 1px solid #fff;
        padding: 0px;
        background: #bcd0f9;
        color: var(--mau1);
        margin: 0px;
        flex: 1;
        height: 40px;
        font-size: 15px;
        border-radius: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      & .react-datepicker__day--selected {
        color: #fff;
        background: var(--mau1);
        font-weight: normal;
      }
      & .react-datepicker__day--disabled {
        color: #454545;
        background: #bcd0f9;
        opacity: 0.35;
      }
      & .react-datepicker__day--outside-month {
        color: transparent;
        pointer-events: none;
        background: transparent;
      }
    }
    & .react-datepicker__navigation {
      position: absolute;
      top: 11px;
      width: unset;
      height: unset;
      cursor: pointer;
      border: none !important;
      border-radius: 3px;
      font-weight: bold;
      &.react-datepicker__navigation--next {
        right: 0.25rem;
        &:hover {
          right: 0.2rem;
        }
      }
      &.react-datepicker__navigation--previous {
        left: 0.25rem;
        &:hover {
          left: 0.2rem;
        }
      }
      &:hover {
        background: #ededed;
        font-weight: normal;
        color: #2b2b2b;
      }
      & > span {
        background-image: unset !important;
        font-family: "Flaticon";
        width: 35px;
        height: 28px;
        line-height: 28px;
        font-size: 20px;
        color: #555;
        text-indent: 0px !important;
        text-align: center;
        background-color: #e3e1e0;
        display: block;
        border-radius: 3px;
        position: static !important;
        margin: unset !important;
      }
      & .react-datepicker__navigation-icon {
        vertical-align: middle;
        overflow: hidden;
        background-repeat: no-repeat;
        background-image: url(imgs/ui-icons_444444_256x240.png);
      }
      & .react-datepicker__navigation-icon--next {
        background-position: -48px -192px;
        &::before {
          content: "\f118";
          font-style: normal;
          /**/
          transform: none;
          left: unset;
          border: none;
          width: auto;
          height: auto;
          position: unset;
        }
      }
      & .react-datepicker__navigation-icon--previous {
        background-position: -80px -192px;
        &::before {
          content: "\f119";
          font-style: normal;
          /**/
          transform: none;
          right: unset;
          border: none;
          width: auto;
          height: auto;
          position: unset;
        }
      }
    }
  }
  & input[type="checkbox"] {
    position: unset;
  }
  & .modal-login {
    &.modal-dialog {
      margin-top: 250px;
      vertical-align: top !important;
    }
    & .modal-body {
      padding: 25px;
    }
  }
  & .btn-primary-v2 {
    background-color: var(--mau1);
    color: var(--color-text-fff);
    box-shadow: none;
    font-weight: 600;
  }
  & .btn-primary-v2:hover {
    background-color: var(--mau1);
    // background-image: linear-gradient(
    //   to right,
    //   rgba(15, 78, 190, 1) 0%,
    //   rgba(39, 108, 238, 1) 51%,
    //   rgba(15, 78, 190, 1) 100%
    // );
    color: var(--color-text-fff);
    box-shadow: none;
  }
  & .btn-primary-v2:active {
    border: 1px solid transparent;
  }
  & .btn-primary-v2.disabled {
    background-color: #c9c9c9;
    opacity: 1;
    background-image: unset;
  }
  & .btn-outline {
    color: var(--color-text-000);
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--color-border-btn);
  }
  & .btn-outline:hover {
    color: var(--color-text-000);
    font-weight: 600;
    font-size: 16px;
    border: 1px solid var(--color-border-btn);
  }
  & .btn-black,
  & .btn-black:hover,
  & .btn-black:focus,
  & .btn-black:active {
    background-color: var(--color-bg-black);
    color: var(--color-text-fff);
    box-shadow: none;
    font-weight: 600;
    border: none;
  }
  & .btn-underline-black,
  & .btn-underline-black:hover,
  & .btn-underline-black:active,
  & .btn-underline-black:focus {
    background-color: transparent;
    color: var(--color-text-000);
    font-weight: 500;
    padding: 0;
    box-shadow: none;
    text-decoration: underline;
    font-size: 16px;
    border: none;
  }
  & .flatpickr-calendar {
    width: 792px !important;
    border-radius: 18px;
    padding: 15px;
    background: var(--color-text-fff);
  }
  & .flatpickr-months {
    padding: 5px 15px 15px 15px;
    border-radius: 18px 0;
    background: var(--color-text-fff);
    & .flatpickr-prev-month,
    & .flatpickr-next-month {
      color: var(--color-text-000);
      fill: var(--color-text-000);
    }
    & .flatpickr-next-month.flatpickr-next-month,
    & .flatpickr-prev-month.flatpickr-next-month {
      top: 14px;
      right: 20px;
    }
    & .flatpickr-prev-month.flatpickr-prev-month,
    & .flatpickr-next-month.flatpickr-prev-month {
      top: 14px;
      left: 20px;
    }
    & .flatpickr-prev-month:hover svg,
    & .flatpickr-next-month:hover svg {
      fill: var(--color-text-000);
    }
    & .flatpickr-month {
      border-radius: 0 18px 0 0;
      background: var(--color-text-fff);
      & .flatpickr-current-month {
        color: var(--color-text-000);
        > span {
          font-weight: 700;
          font-size: 16px;
        }

        & .numInputWrapper:hover,
        & .cur-month:hover {
          background: transparent;
        }
        & .arrowUp,
        & .arrowDown {
          display: none;
        }
        & input {
          font-weight: 700;
          font-size: 16px;
          box-shadow: none;
          border: 0;
          user-select: none;
          pointer-events: none;
        }
        & input:hover {
        }
      }
    }
    & .flatpickr-month:nth-child(2) {
      border-radius: 18px 0 0 0;
    }
  }
  & .flatpickr-weekdays {
    background: transparent;
    & .flatpickr-weekdaycontainer:first-child {
      margin-right: 60px;
    }
    & .flatpickr-weekday {
      background: transparent;
      color: var(--color-text-000);
      font-size: 14px;
      font-weight: 400;
    }
  }
  & .flatpickr-innerContainer {
    border-radius: 0 0 18px 18px;
    border-bottom: none;
  }
  & .flatpickr-days {
    width: 762px !important;
    border-left: none;
    border-right: none;
    & .dayContainer:first-child {
      margin-right: 60px;
    }
  }
  & .dayContainer {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
  }
  & .dayContainer + .dayContainer {
    box-shadow: none;
  }
  & .flatpickr-day {
    max-width: 48px;
    min-width: 48px;
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-text-000);
    &.inRange {
      box-shadow: -2px 0px 0px var(--color-bg-inrange-calendar),
        5px 0px 0 var(--color-bg-inrange-calendar);
      background: var(--color-bg-inrange-calendar);
      border-color: var(--color-bg-inrange-calendar);
    }
    &.selected.startRange + .endRange:not(:nth-child(7n + 1)),
    &.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
    &.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
      box-shadow: none;
    }
    &:hover {
      color: var(--color-text-fff);
      background: var(--color-text-000);
      border-color: var(--color-text-000);
    }
    &.today {
      border-color: var(--color-text-000);
    }
    &.selected {
      color: var(--color-text-fff);
      background: var(--color-text-000);
      border-color: var(--color-text-000);
      &:hover {
        color: var(--color-text-fff);
        background: var(--color-text-000);
        border-color: var(--color-text-000);
      }
      &.startRange {
        border-radius: 50px;
        &::before {
          content: " ";
          display: block;
          position: absolute;
          width: 48px;
          height: 48px;
          z-index: -1;
          background: var(--color-bg-inrange-calendar);
          border-radius: 50px 0 0 50px;
          margin-top: -1px;
          box-shadow: 9px 0px 0px 0px var(--color-bg-inrange-calendar);
        }
      }
      &.endRange {
        border-radius: 50px;
        &::before {
          content: " ";
          display: block;
          position: absolute;
          width: 39px;
          height: 39px;
          z-index: -1;
          background: var(--color-bg-inrange-calendar);
          border-radius: 0 50px 50px 0;
          margin-top: -1px;
          margin-left: -1px;
        }
      }
      &.endRange.startRange {
        &::before {
          display: none;
        }
      }
    }
    &.endRange.endRange {
      border-radius: 50px;
      color: var(--color-text-fff);
      background: var(--color-text-000);
      border-color: var(--color-text-000);
      &::before {
        content: " ";
        display: block;
        position: absolute;
        width: 48px;
        height: 48px;
        z-index: -1;
        background: var(--color-bg-inrange-calendar);
        border-radius: 0 50px 50px 0;
        margin-top: -1px;
        margin-left: -1px;
      }
    }
    &.startRange.startRange {
      border-radius: 50px;
      color: var(--color-text-fff);
      background: var(--color-text-000);
      border-color: var(--color-text-000);
      &::before {
        content: " ";
        display: block;
        position: absolute;
        width: 48px;
        height: 48px;
        z-index: -1;
        background: var(--color-bg-inrange-calendar);
        border-radius: 50px 0 0 50px;
        margin-top: -1px;
        box-shadow: 9px 0px 0px 0px var(--color-bg-inrange-calendar);
      }
    }
    &.flatpickr-disabled,
    &.flatpickr-disabled:hover {
      color: var(--color-text-b3);
    }
  }
  & .flatpickr-calendar.multiMonth {
    & .flatpickr-days {
      & .dayContainer:nth-child(n + 2) {
        & .flatpickr-day.inRange:nth-child(7n + 1) {
          box-shadow: none;
        }
      }
    }
  }
  & .min-height-100px {
    min-height: 100px;
  }
  & .cursor-pointer {
    cursor: pointer;
  }
  & .mb-25px {
    margin-bottom: 25px;
  }
  & .mb-40px {
    margin-bottom: 40px;
  }
  & .mt-15px {
    margin-top: 15px;
  }
  & .mt-10px {
    margin-top: 10px;
  }
  & .pt-15px {
    padding-top: 15px !important;
  }
  & .btn-sg {
    min-height: 43px;
  }
  & .text-align-center {
    text-align: center;
  }
  & .fw-700 {
    font-weight: 700;
  }
  & .fw-600 {
    font-weight: 600;
  }
  & .text-underline {
    text-decoration: underline;
  }
  & .text-mauden {
    color: var(--mauden);
  }
  & .pt-0px {
    padding-top: 0;
  }
  & .mt-30px {
    margin-top: 30px;
  }
  & .mb-16px {
    margin-bottom: 16px;
  }
  & .mt-16px {
    margin-top: 16px;
  }
  & .modal {
    & .modal-understood-footer {
      & .modal-footer {
        border-top: none;
        & button.btn-secondary {
          text-decoration: none;
          background-color: var(--mau1) !important;
          color: var(--color-text-fff) !important;
          box-shadow: none;
          font-weight: 600;
          border: none;
          min-height: 43px;
          padding: 6px 12px;
          width: 100%;
          &:hover {
            background-color: var(--mau1);
            color: var(--color-text-fff);
            box-shadow: none;
          }
        }
      }
    }
    & .modal-scrollable-inside {
      max-height: calc(100vh - 4.5rem);
      & .modal-body {
        overflow: auto;
      }
    }
    & .modal-back-header {
      & .modal-header {
        & .close {
          right: unset;
          box-shadow: none;
          font-size: 22px !important;
          font-weight: 500;
          color: #4f4f4f;
          & span {
            display: none;
            font-weight: 400;
          }
          &::before {
            content: "\f121";
            font-family: "flaticon_flaticon_v2";
            color: var(--color-text-000);
            font-size: 14px;
          }
        }
      }
    }
    & .modal-no-title {
      & .modal-header {
        & .close {
          left: 8px;
          top: 25px;
        }
      }
    }
  }
  & .justify-between {
    justify-content: space-between;
  }
  & .justify-end {
    justify-content: flex-end;
  }
  & .justify-center {
    justify-content: center;
  }
  @media (min-width: 567px) {
    .modal .modal-dialog-scrollable-new {
      max-height: calc(100% - 30px - 1.75rem);
    }
  }
  @media (min-width: 768px) {
    .modal .modal-dialog-scrollable-new {
      max-height: calc(100% - 60px);
    }
    .navbar-header {
      float: left;
    }
    .navbar-brand {
      display: block;
    }
    .container {
      width: 750px;
    }
    .container-fluid > .navbar-collapse,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container > .navbar-header {
      margin-right: 0;
      margin-left: 0;
    }
    .navbar-collapse {
      width: auto;
      border-top: 0;
      box-shadow: none;
      &.collapse {
        display: block !important;
        height: auto !important;
        padding-bottom: 0;
        overflow: visible !important;
      }
    }
    .pull-sm-left {
      float: left !important;
    }
    .nav {
      margin-top: 8px !important;
    }
    .navbar-nav {
      float: left;
      margin: 0;
      & > li {
        float: left;
        & > a {
          //   padding-top: 15px;
          //   padding-bottom: 15px;
        }
      }
    }
    .navbar-right {
      float: right !important;
      margin-right: -15px;
    }
    .hidden-desktop,
    .navbar-collapse.collapse.hidden-desktop {
      display: none !important;
    }
    &:not(.home) {
      padding-top: 0px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }
  @media (min-width: 1200px) {
    & .c-body {
      padding-top: 60px !important;
    }
    .container {
      width: 1284px;
    }
  }
  @media (min-width: 1160px) {
    .modal-lg {
      max-width: 1160px;
    }
    .modal-filter.modal-lg {
      max-width: 850px;
    }
    .modal-order-detail.modal-lg {
      max-width: 1100px;
    }
  }
  @media (min-width: 1200px) {
    .modal-order-detail.modal-lg {
      max-width: 1160px;
    }
    .modal-filter.modal-lg {
      max-width: 850px;
    }
  }
  @media (max-width: 1010px) {
    .container {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
  @media (max-height: 850px) {
    & .modal-login {
      &.modal-dialog {
        margin-top: 120px;
      }
    }
  }
  @media (max-height: 700px) {
    & .modal-login {
      &.modal-dialog {
        margin-top: 30px !important;
      }
    }
  }
  @media (max-width: 1170px) {
    .container {
      width: 100%;
      max-width: 100%;
    }
  }
  @media (max-width: 1010px) {
    & .modal {
      & .modal-dialog:not(.modal-dialog-alert) {
        width: 100% !important;
        max-width: 100% !important;
        vertical-align: bottom;
        margin: 0 !important;
        max-height: calc(100% - 1.5rem) !important;
        display: flex;
        position: fixed !important;
        bottom: 0 !important;
        left: 0% !important;
        right: 0% !important;
        margin-bottom: 0 !important;
        & .modal-body {
          border-radius: 20px 20px 0 0;
          padding: 16px !important;
        }
        & .modal-content {
          border-radius: 20px 20px 0 0;
          overflow: hidden;
        }
      }
      & .modal-dialog-scrollable-new {
        max-height: calc(100% - 1.5rem) !important;
        padding-bottom: 0;
        height: unset;
      }
      & .modal-scrollable-inside {
        max-height: unset;
        height: unset !important;
      }
      &.fade {
        & .modal-dialog {
          transform: translate(0, 50px);
        }
      }
      &.show {
        & .modal-dialog {
          transform: none;
        }
      }
      & .modal-full:not(.modal-dialog-alert) {
        height: 100% !important;
        max-height: 100% !important;
        padding-top: 0 !important;
        & .modal-body {
          border-radius: 0;
          overflow-y: auto;
        }
        & .modal-content {
          border-radius: 0;
        }
      }
      & .modal-search-mobile {
        & .modal-header {
          padding: 16px 0;
          background-color: #f6f6f6;
          border-bottom: none;
          position: relative;
          border-radius: 0;
          button {
            display: none;
          }
          & .modal-title {
            padding-left: 0px !important;
          }
        }
        & .modal-body {
          padding-top: 0 !important;
          background-color: #f6f6f6;
          margin-bottom: 80px;
        }
      }
      & .modal-dialog-alert {
        width: calc(100% - 2rem) !important;
        padding: 0;
        margin: 0 1rem;
        display: flex;
        & .modal-body {
          padding-bottom: 0 !important;
        }
        & .modal-footer {
          border-top: none;
          padding: 20px;
          & .btn,
          & .btn:hover,
          & .btn:focus,
          & .btn:active {
            background-color: var(--color-bg-black);
            color: var(--color-text-fff);
            box-shadow: none;
            font-weight: 600;
            border: none;
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    & .modal-login {
      &.modal-dialog {
        margin-top: 30px !important;
      }
    }
    & .modal {
      & .modal-scrollable-inside {
        padding-top: 0;
        // max-height: calc(100vh - 3.5rem);
      }
      & .modal-filter {
        & .modal-header {
          & .modal-title {
            padding-left: 20px;
            font-size: 18px !important;
          }
          & .close {
            margin-left: -10px;
          }
        }
      }
    }
  }
}
// .modal-custom-style {
//   @media (max-width: 996px) {
//     & .modal {
//       & .modal-dialog {
//         padding-top: 0;
//         margin-top: 0.5rem;
//         max-height: calc(100% - 1rem);
//         height: calc(100% - 1rem);
//         &.modal-scrollable-inside {
//           max-height: calc(100% - 1rem);
//         }
//       }
//     }
//   }
// }
.html_content {
  font-weight: 400;
  & ul {
    list-style-type: disc;
  }
  & ol,
  & ul {
    padding-left: 2rem;
  }
}
@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }
  .modal-dialog {
    width: 600px;
    max-width: 600px;
    margin: 30px auto;
    &.filter-header-category {
      width: 674px;
      max-width: 674px;
    }
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
@media (min-width: 992px) {
  & .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  & .col-md-10 {
    width: 83.33333333%;
  }
}
@media (min-width: 950px) and (max-width: 1159px) {
  & .modal-order-detail.modal-lg {
    max-width: 900px !important;
    width: 900px;
  }
}
@media (min-width: 850px) and(max-width: 949px) {
  & .modal-order-detail.modal-lg {
    max-width: 800px !important;
    width: 800px;
  }
}
@media (min-width: 1011px) {
  & .flatpickr-calendar {
    margin: 0 auto;
  }
}
@media (min-width: 1300px) {
  & .theme-sigo .c-footer .container {
    width: 90%;
    max-width: 90%;
  }
  & .theme-sigo .container {
    width: 90%;
    max-width: 90%;
  }
}
@media (max-width: 1199px) {
  & .page-home {
    overflow-x: hidden;
  }
}
@media (max-width: 767px) {
  .modal-dialog {
    width: 80%;
  }
  & .modal-login.modal-dialog {
    & .modal-body {
      padding: 15px;
    }
  }
  .html_content {
    & figure.image {
      & > img {
        object-fit: scale-down;
        width: 100%;
        height: 100%;
      }
    }
    & p {
      & > img {
        object-fit: scale-down;
        width: 100%;
        height: 100%;
      }
    }
  }
}
@media (max-width: 580px) {
  .modal-body {
    padding: 20px !important;
  }
  & .modal-login {
    & .modal-body {
      padding: 15px !important;
    }
  }
}
