@font-face {
    font-family: "flaticon_flaticon_v2";
    src: url("./flaticon.ttf?6392e2eb2fb2256ca08f514fed29faae") format("truetype");
}

i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon_flaticon_v2 !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-gps-navigation_old:before {
    content: "\f101";
}

.flaticon-map_old:before {
    content: "\f102";
}

.flaticon-bluetooth_old:before {
    content: "\f103";
}

.flaticon-technology_old:before {
    content: "\f104";
}

.flaticon-view:before {
    content: "\f105";
}

.flaticon-camera_old:before {
    content: "\f106";
}

.flaticon-camera-1:before {
    content: "\f107";
}

.flaticon-appetite_old:before {
    content: "\f108";
}

.flaticon-car_old:before {
    content: "\f109";
}

.flaticon-wifi_old:before {
    content: "\f10a";
}

.flaticon-airbag:before {
    content: "\f10b";
}

.flaticon-scooter:before {
    content: "\f10c";
}

.flaticon-signature:before {
    content: "\f10d";
}

.flaticon-search:before {
    content: "\f10e";
}

.flaticon-star:before {
    content: "\f10f";
}

.flaticon-facebook:before {
    content: "\f110";
}

.flaticon-youtube:before {
    content: "\f111";
}

.flaticon-instagram:before {
    content: "\f112";
}

.flaticon-tiktok:before {
    content: "\f113";
}

.flaticon-pin_old:before {
    content: "\f114";
}

.flaticon-time:before {
    content: "\f115";
}

.flaticon-car-1:before {
    content: "\f116";
}

.flaticon-car-insurance:before {
    content: "\f117";
}

.flaticon-docs:before {
    content: "\f118";
}

.flaticon-engine:before {
    content: "\f119";
}

.flaticon-automatic-transmission:before {
    content: "\f11a";
}

.flaticon-manual-transmission:before {
    content: "\f11b";
}

.flaticon-promotion:before {
    content: "\f11c";
}

.flaticon-location:before {
    content: "\f11d";
}

.flaticon-medal:before {
    content: "\f11e";
}

.flaticon-adjust:before {
    content: "\f11f";
}

.flaticon-right-arrow:before {
    content: "\f120";
}

.flaticon-left-arrow:before {
    content: "\f121";
}

.flaticon-heart:before {
    content: "\f122";
}

.flaticon-user:before {
    content: "\f123";
}

.flaticon-micro:before {
    content: "\f124";
}

.flaticon-cabriolet:before {
    content: "\f125";
}

.flaticon-supercar:before {
    content: "\f126";
}

.flaticon-sedan:before {
    content: "\f127";
}

.flaticon-hatchback:before {
    content: "\f128";
}

.flaticon-suv:before {
    content: "\f129";
}

.flaticon-cuv:before {
    content: "\f12a";
}

.flaticon-suv-1:before {
    content: "\f12b";
}

.flaticon-minivan:before {
    content: "\f12c";
}

.flaticon-pick-up:before {
    content: "\f12d";
}

.flaticon-van:before {
    content: "\f12e";
}

.flaticon-bus:before {
    content: "\f12f";
}

.flaticon-fuel:before {
    content: "\f130";
}

.flaticon-engine-oil:before {
    content: "\f131";
}

.flaticon-electric-car:before {
    content: "\f132";
}

.flaticon-upload:before {
    content: "\f133";
}

.flaticon-shield:before {
    content: "\f134";
}

.flaticon-profile:before {
    content: "\f135";
}

.flaticon-wallet:before {
    content: "\f136";
}

.flaticon-dots-menu:before {
    content: "\f137";
}

.flaticon-copy:before {
    content: "\f138";
}

.flaticon-filter:before {
    content: "\f139";
}

.flaticon-close:before {
    content: "\f13a";
}

.flaticon-pencil:before {
    content: "\f13b";
}

.flaticon-floppy-disk:before {
    content: "\f13c";
}

.flaticon-checked:before {
    content: "\f13d";
}

.flaticon-verified:before {
    content: "\f13e";
}

.flaticon-new-moon:before {
    content: "\f13f";
}

.flaticon-add:before {
    content: "\f140";
}

.flaticon-electricity:before {
    content: "\f141";
}

.flaticon-location-1:before {
    content: "\f142";
}

.flaticon-electric-car-1:before {
    content: "\f143";
}

.flaticon-check:before {
    content: "\f144";
}

.flaticon-call:before {
    content: "\f145";
}

.flaticon-information:before {
    content: "\f146";
}

.flaticon-image:before {
    content: "\f147";
}

.flaticon-usb_old:before {
    content: "\f148";
}

.flaticon-tyre:before {
    content: "\f149";
}

.flaticon-video_old:before {
    content: "\f14a";
}

.flaticon-sports-car:before {
    content: "\f14b";
}

.flaticon-share:before {
    content: "\f14c";
}

.flaticon-twitter:before {
    content: "\f14d";
}

.flaticon-pinterest:before {
    content: "\f14e";
}

.flaticon-option:before {
    content: "\f14f";
}

.flaticon-gps-navigation:before {
    content: "\f150";
}

.flaticon-map:before {
    content: "\f151";
}

.flaticon-technology:before {
    content: "\f152";
}

.flaticon-view_old:before {
    content: "\f153";
}

.flaticon-appetite:before {
    content: "\f154";
}

.flaticon-car:before {
    content: "\f155";
}

.flaticon-pin:before {
    content: "\f156";
}

.flaticon-usb:before {
    content: "\f157";
}

.flaticon-video:before {
    content: "\f158";
}

.flaticon-wifi:before {
    content: "\f159";
}

.flaticon-camera:before {
    content: "\f15a";
}

.flaticon-bluetooth:before {
    content: "\f15b";
}

.flaticon-copying:before {
    content: "\f15c";
}

.flaticon-car-2:before {
    content: "\f15d";
}

.flaticon-scooter-1:before {
    content: "\f15e";
}

.flaticon-money-stack:before {
    content: "\f15f";
}

.flaticon-list:before {
    content: "\f160";
}

.flaticon-id-card:before {
    content: "\f161";
}

.flaticon-driving-license:before {
    content: "\f162";
}

.flaticon-steering-wheel:before {
    content: "\f163";
}

.flaticon-play-button:before {
    content: "\f164";
}

.flaticon-clock:before {
    content: "\f165";
}

.flaticon-block:before {
    content: "\f166";
}

.flaticon-addition:before {
    content: "\f167";
}

.flaticon-mail:before {
    content: "\f168";
}

.flaticon-resume:before {
    content: "\f169";
}

.flaticon-refresh:before {
    content: "\f16a";
}

.flaticon-printing:before {
    content: "\f16b";
}

.flaticon-printing-1:before {
    content: "\f16c";
}

.flaticon-telephone:before {
    content: "\f16d";
}

.flaticon-phone-call:before {
    content: "\f16e";
}

.flaticon-information-pamphlet:before {
    content: "\f16f";
}

.flaticon-local-history:before {
    content: "\f170";
}

.flaticon-car-3:before {
    content: "\f171";
}

.flaticon-star-1:before {
    content: "\f172";
}

.flaticon-car-4:before {
    content: "\f173";
}

.flaticon-message:before {
    content: "\f174";
}

.flaticon-user-1:before {
    content: "\f175";
}

.flaticon-home:before {
    content: "\f176";
}

.flaticon-arrow:before {
    content: "\f177";
}

.flaticon-down-arrow:before {
    content: "\f178";
}

.flaticon-down-arrow-1:before {
    content: "\f179";
}

.flaticon-up-arrow:before {
    content: "\f17a";
}

.flaticon-dollar:before {
    content: "\f17b";
}

.flaticon-column-chart:before {
    content: "\f17c";
}

.flaticon-settings:before {
    content: "\f17d";
}

.flaticon-key:before {
    content: "\f17e";
}

.flaticon-calendar-1:before {
    content: "\f17f";
}

.flaticon-menu:before {
    content: "\f180";
}

.flaticon-copy-writing:before {
    content: "\f181";
}

.flaticon-partnership:before {
    content: "\f182";
}

.flaticon-policy-privacy:before {
    content: "\f183";
}

.flaticon-language:before {
    content: "\f184";
}

.flaticon-user-account:before {
    content: "\f185";
}

.flaticon-dong:before {
    content: "\f186";
}

.flaticon-back-button:before {
    content: "\f187";
}
.flaticon-notification:before {
    content: "\f188";
}
.flaticon-checked-1:before {
    content: "\f189";
}
.flaticon-save-money:before {
    content: "\f18a";
}
.flaticon-close-1:before {
    content: "\f18b";
}