
:root{
  --mau1:#3366ff;
  --mau2:#0069bc;
  --vang:#FFCC36;
  --xam: #777;
  --den: #000;
}
.mau1{
  color: var(--mau1);
}
.radius20px{
  border-radius: 20px;
}
.bold{
  font-weight: bold;
}
.btn-md{
  padding: 10px 20px;
}
.btn{
  cursor: pointer;
  vertical-align: middle;
}
.btn-lg{
  line-height: 25px;
}
.btn [class*="flaticon-"]{
  vertical-align: middle;
}
.btn-lg [class*="flaticon-"]{
  vertical-align: middle;
  font-size: 25px;
  padding-left: 10px;
}
.btn.active, .btn:active, .open>.dropdown-toggle.btn{
  border: none;
}
.btn-den{
  background-color: #313334;
  color: white;
}
.btn-den:focus, .btn-den:active, .btn-den:hover{
  color: #e5e5e5;
}
.owl-nav.disabled{
  display: none;
}
.owl-nav .fa{
  font-size: 30px;
  line-height: 30px;
  color: #666;
}
.owl-theme .owl-nav [class*='owl-']{
  background: none !important;
  padding: 0px;
}
.owl-prev{
  position: absolute;
  left: 0px;
  top: calc( 50% - 15px );
  z-index: 333;
}
.owl-next{
  position: absolute;
  right: 0px;
  top: calc( 50% - 15px );
  z-index: 333;
}
.owl-nav .disabled{
  display: none;
}
.modal-content{
  border-radius: 20px;
}
.modal .close{
  background-color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  font-size: 20px;
  position: absolute;
  top: -10px;
  right: -10px;
  box-shadow: 0px 0px 5px #888;
  opacity: 1;
  cursor: pointer;
  z-index: 999;
}
.xam{
  color: #666;
}
.den{
  color: #000;
}
.bgxam{
  background-color: #e5e5e5 !important;
}
body:not(.home) nav .line{
  display: none;
}
.rotate180deg{
  transform: rotate(180deg);
  display: block;
}
.owl-nav .circel{
  display: block;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 0px 6px #999;
  height: 35px;
  width: 35px;
  text-align: center;
  line-height: 39px;
  color: #888;
  font-size: 16px;
}
.owl-nav .owl-prev{
  left: 5px;
}
.owl-nav .owl-next{
  right: 5px;
}
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
  background: rgba(51,102,255,0.9) !important;
  width: 10px !important;
}
.popover{
  max-width: 400px;
}
.size13px{
  font-size: 13px;
}
.pac-container{
  z-index: 9999;
}
.swal-text{
  font-weight: 300;
}
.textlight{
  font-weight: 300;
}
[class*="flaticon-"]{
  vertical-align: middle;
}
html .square{
  border-radius: 0px;
}
h1, h2, h3, h4{
  color: #000;
}
.modal-dialog{
  margin-top: 30px;
}
.bootstrap-select .dropdown-menu{
  padding-top: 0px !important;
  background-color: #fff !important;
}
.bootstrap-select .dropdown-menu > li:first-child a{
  border-radius: 0px !important;
}
.bootstrap-select .btn{
  border-radius: 4px !important;
}
.bootstrap-select .dropdown-menu a{
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}
.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

/*** login ***/
.panel-login {
    border-color: #ccc;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.2);
}
.panel-login>.panel-heading {
    color: #00415d;
    background-color: #fff;
    border-color: #fff;
    text-align:center;
}
.panel-login>.panel-heading a{
    text-decoration: none;
    color: #666;
    font-weight: bold;
    font-size: 15px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
}
.panel-login>.panel-heading a.active{
    color: var(--mau1);
    font-size: 18px;
}
.panel-login>.panel-heading hr{
    margin-top: 10px;
    margin-bottom: 0px;
    clear: both;
    border: 0;
    height: 1px;
    background-image: -webkit-linear-gradient(left,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.15),rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
    background-image: -ms-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
    background-image: -o-linear-gradient(left,rgba(0,0,0,0),rgba(0,0,0,0.15),rgba(0,0,0,0));
}
.panel-login input[type="text"],.panel-login input[type="email"],.panel-login input[type="password"] {
    height: 45px;
    border: 1px solid #ddd;
    font-size: 16px;
    -webkit-transition: all 0.1s linear;
    -moz-transition: all 0.1s linear;
    transition: all 0.1s linear;
}
.panel-login input:hover,
.panel-login input:focus {
    outline:none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border-color: #ccc;
}
.btn-login {
    background-color: var(--mau1);
    outline: none;
    color: #fff;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border-color: var(--mau1);
}
.btn-login:hover,
.btn-login:focus {
    color: #fff;
    background-color: var(--mau1);
    border-color: var(--mau1);
}
.forgot-password {
    text-decoration: underline;
    color: #888;
}
.forgot-password:hover,
.forgot-password:focus {
    text-decoration: underline;
    color: #666;
}

.btn-register {
    background-color: var(--mau1);
    outline: none;
    color: #fff;
    font-size: 14px;
    height: auto;
    font-weight: normal;
    padding: 14px 0;
    text-transform: uppercase;
    border-color: var(--mau1);
}
.btn-register:hover,
.btn-register:focus {
    color: #fff;
    background-color: var(--mau1);
    border-color: var(--mau1);
}
.modal-body .panel{
  margin: 0px;
}
.wpsearch select.form-control{
  height: 45px;
}
.chontg{
  color: red;
}
.modal-body{
  padding: 35px;
}
#signin .logo{
  margin: 0px auto 20px;
  display: block;
}
.titledk{
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
}
input.inputsdt{
  border: none;
  border-bottom: 3px solid #e5e5e5;
  padding: 0px;
  box-shadow: none;
  font-size: 20px;
  line-height: 55px;
  height: 55px;
  border-radius: 0px;
  color: #222;
  transform: translateZ(0);
  opacity: 1;
}
input.inputsdt:focus{
  box-shadow: none;
}
input::placeholder {
  color: #aaa;
}
input::-webkit-input-placeholder{
  color:#aaa;
}
input:-moz-placeholder {
  color:#aaa;
}
input::-webkit-input-placeholder {  /* Chrome/Opera/Safari */
  color: #aaa;
}
input::-moz-placeholder {  /* Firefox 19+ */
  color: #aaa;
}
input:-moz-placeholder { /* Firefox 18- */
  color: #aaa;
}
input:-ms-input-placeholder {  /* Edge/IE 10+ */
  color: #aaa;
}
.showmessage{
  display: none;
  margin-bottom: 10px;
  color:red
}
.showmessage.success{
  color:rgb(67, 169, 58)
}
.tabdk{
  display: none;
}
.tabdk.active{
  display: block;
}
.btnbackdk{
  font-size: 30px;
  line-height: 55px;
  margin-bottom: 20px;
  cursor: pointer;
  color: #333;
}
.wpotp{
  margin-bottom: 40px;
}
.wpotp .form-control {
  -webkit-transition: none;
  transition: none;
  width: 40px;
  height: 50px;
  padding: 0px;
  line-height: 40px;
  font-size: 30px;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
}
.wpotp .form-control:focus {
  color: #3F4254;
  background-color: #ffffff;
  border-color: #884377;
  outline: 0;
}
.wpotp .form-control.form-control-solid {
  background-color: #fff;
  border: none;
  border-bottom: 1px solid var(--mau1);
  color: #3F4254;
  box-shadow: none;
  border-radius: 0px;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.wpotp .form-control.form-control-solid:active,
.wpotp .form-control.form-control-solid.active,
.wpotp .form-control.form-control-solid:focus,
.wpotp .form-control.form-control-solid.focus {
  color: #3F4254;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}
.hienpass{
  position: absolute;
  bottom: 4px;
  right: 10px;
  z-index: 1;
  color: var(--mau1);
}
.btnchangetab{
  font-size: 20px;
}
.form-control.line-bottom{
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0px;
  box-shadow: none;
  padding-left: 0px;
  padding-right: 0px;
}
.form-control:hover, .form-control:focus, .form-control:active{
  border-color: var(--mau1);
}
.form-control.line-bottom:hover, .form-control.line-bottom:focus{
  box-shadow: none;
}
.btnchangetab.sm{
  font-size: 17px;
  line-height: 25px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
@media (max-width: 414px){
  .wpotp .form-control{
    margin-right: 13px;
  }
}

/*** top ***/
#top{
  background: #1e1e1e;
  padding: 5px 0px;
  color: white;
}
#top li{
  display:inline-block;
  margin-left:10px;
}
#top a{
  color:white;
}
#top ul .fa{
  font-size:19px;
}
.arvartimg{
  max-height:40px;
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  display: inline-block;
  float:right;
  padding:4px;
  border:1px solid #fff;
}
.noimage.arvartimg{
  border: none;
  border-radius: 0;
  padding: 0px;
  max-height: 37px;
}
.pt-0px{
  padding-top:0px !important;
}
.pb-0px{
  padding-bottom:0px !important;
}
.navbar{
  background: transparent;
  border:none;
  border-radius:0px;
  -moz-border-radius:0px;
  margin:0px;
  position: relative;
  z-index: 999;
}
.navbar-brand{
  height:auto;
  min-height:30px;
  border:0px;
  padding:0px;
}
.btn-group-lg > .btn, .btn-lg{
  font-size: 17px;
}
.navbar-right .fa{
  line-height: 20px;
  float: left;
  margin-right: 5px;
}
.linkhotline .fa{
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  border: 1px solid #222;
  border-radius: 50%;
  transition: border 1s;
}
.home .navbar:not(.truot) .linkhotline .fa{
  border-color: white;
}
.wp-icobar .icon-bar{
  background: #555;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  display: block;
  width: 20px;
  height: 2px;
  padding-right: 6px;
  border-radius: 1px;
}
.wp-icobar{
  display: inline-block;
  padding-top: 14px
}
.wp-icobar .mid{
  margin: 4px 0px;
}
.navavatar{
  float:left;
  padding-right:0px;
  background: #fff !important;
  border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: 0px 0px 5px #aaa;
  padding-left: 15px;
  padding-right: 0px !important;
}
.home .logo{
  filter: brightness(0) invert(1);
}
.numalert{
  background-color: red;
  width: 18px;
  height: 18px;
  font-size: 13px;
  border-radius: 50%;
  text-align: center;
  line-height: 18px;
  position: absolute;
  top: 0px;
  right: 0px;
  color: white;
  display: none;
}
.notethongbaomenu{
  display: none;
}
.active.numalert, .notethongbaomenu.active{
  display: block;
}
.notethongbaomenu.active{
  display: inline-block;
}

@media (min-width: 768px){
  body:not(.home){
    padding-top: 70px ;
  }
  .navbar{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 999;
    transition: all 1s;
  }
  .truot{
    background-color: #fff;
    box-shadow: 0px 0px 4px #888;
  }
  .navbar .line{
    height: 1px;
    margin: 10px 0px;
    background: rgba( 255,255,255,0.2);
    transition: all 1s;
  }
  .truot.navbar .line{
    height: 0px;
    margin: 0px;
    border-width: 0px;
  }
  #content #slider{
    padding-top: 110px;
  }
  .collapseRight .dropdown-menu{
    top:40px;
    border:none;
    left:auto;
    right:0px;
    border-radius: 7px;
  }
  .collapseRight .dropdown-menu li:first-child a:hover{
    border-radius: 7px 7px 0px 0px;
  }
  .collapseRight .dropdown-menu li:last-child a:hover{
    border-radius: 0px 0px 7px 7px;
  }
  #collapseLeft .navbar-nav > li > a{
    padding-bottom:10px;
  }
  #collapseLeft .dropdown-menu{
    top:33px;
  }
  .navbar{
    padding-top:10px;
    padding-bottom:10px;
  }
  .navbar-toggle.pull-left {
    margin-left: 15px;
  }
  .navbar-nav > li > a:hover, .navbar-nav > li > a:focus, .navbar-nav > li > a:active, .nav > li.active > a, .nav > li > a:focus, .nav > li > a:hover, .nav .open > a, .nav .open > a:focus, .nav .open > a:hover{
    background: none;
    color: var(--mau1);
  }
  .navbar-nav > li > a{
    padding-top:9px;
    padding-bottom:5px;
    color: #222;
  }
  .home .navbar:not(.truot) .navbar-nav > li > a{
    color: white;
  }
  .navbar-nav > li > .btn{
    color: white
  }
  .nav{
    margin-top: 8px;
  }
  #top ul{
    text-align:right;
  }
  .dropdown-menu{
    font-size:unset;
    padding:0px;
  }
  .navbar-brand{
    padding: 5px 0px;
    margin: 0px !important;
  }
  .navbar-brand>img{
    max-height:40px;
  }
  .guest .navbar-nav>li{
    height: 40px;
  }
  .navbar-nav .dropdown-menu a{
    background-color: white;
    font-weight: 300;
    padding: 9px 15px;
    font-size: 15px;
  }
  .dropdown-menu .divider{
    margin: 0px;
  }
  .guest .dropdown-menu{
    background-color: transparent;
    padding-top: 10px;
    border: none;
  }
  .guest .dropdown-menu > li:first-child a{
    border-radius: 5px 5px 0px 0px;
    position: relative;
  }
  .guest .dropdown-menu > li:first-child a:before{
    width: 0;
    height: 0;
    content: "";
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    position: absolute;
    top: -6px;
    right: 40px;
  }
  #collapseLeft .dropdown-menu > li:first-child a:before{
    right: unset;
    left: 40px;
  }
  .guest .dropdown-menu > li:last-child a{
    border-radius: 0px 0px 5px 5px ;
    border-bottom: none;
  }
  .dropdown-menu>.active>a, .dropdown-menu>.active>a:focus, .dropdown-menu>.active>a:hover{
    background-color: var(--mau1);
    color: white;
    padding-top: 12px; 
    padding-bottom: 12px; 
  }
  .guest .dropdown-menu > .active:first-child a:before{
    border-bottom: 10px solid var(--mau1);
  }
  li:hover .dropdown-menu{
    display: block;
  }
  .adminbody .dropdown-menu{
    top:41px;
  }
  .btnbell{
    font-size: 20px;
    padding-bottom: 20px !important;
  }
  #alertstop{
    left: auto;
    right: 0px;
    color: #000;
    min-width: 313px;
    overflow-y: auto;
  }
}
@media ( max-width: 767px ){
  html #collapseRightDesktop{
    display: none !important;
  }
  .navavatar{
    float: right;
    margin-top: 5px;
    margin-right: 10px;
  }
  .wp-icobar{
    padding-top: 11px;
  }
  .arvartimg{
    max-height: 35px;
  }
  .home .logo{
    filter: none;
  }
  .navbar .line{
    margin: 0px;
  }
  .wpinput{
    width: 100%;
    margin-bottom: 10px;
  }
  .nvasearch .ttsearchh{
    color: #111;
  }
}

/*** search home slider ***/
#slider{
  margin-bottom: 70px;
  min-height: 300px;
}
#slider .logan{
  font-size: 35px;
  line-height: 50px;
  margin-bottom: 10px;
  font-family: 'UTM Libel KT';
  color: white;
}
.wpsearchsl{
  height: 65px;
  border-radius: 35px;
  background-color: white;
  padding: 10px 10px 10px 20px;
}
.wpinput{
  width: 30%;
  float: left;
  padding: 0px 10px;
}
.wpinput .nice-select, .ndsearch .nice-select{
  float: none;
  clear: none;
  display: inline-block;
  border: none;
  padding: 0px 20px 0px 0px;
  line-height: 22px;
  height: 22px;
  box-shadow: none;
}
.home .wpinput .nice-select, .home .ndsearch .nice-select{
  float: right;
}
.wpinput .nice-select .list, .ndsearch .nice-select .list{
  top: 30px;
}
.wpinput .nice-select:after, .ndsearch .nice-select:after{
  display: none;
}
.wpinput .nice-select .current, .ndsearch .nice-select .current{
  font-size: 16px;
  color: #555;
}
.nice-select .list{
  max-height: 300px;
  overflow-y: auto !important;
}
.wpinput input:focus{
  box-shadow: none;
}
.btnsearch{
  background-color: var(--mau1);
  color: white;
  height: 45px;
  width: 45px;
  padding: 0px;
  border: none;
  float: right;
  line-height: 45px;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.popvitri{
  position: absolute;
  top: calc( 100% + 10px );
  left: 0px;
  background: #fff;
  padding: 7px 10px;
  z-index: 10;
  width: 100%;
  display: none;
  border-radius: 8px;
}
.popvitri a{
  color: #222;
  line-height: 35px;
}
.popvitri [class*="flaticon-"]{
  width: 25px;
  font-size: 19px;
  vertical-align: middle;
}
.checklocation{
  color: var(--mau1);
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 20px;
}
.wpvitri{
  position: relative;
}

/*** jquery ui ***/
.ui-datepicker{
  width: 21em;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 6px #aaa;
}
.ui-datepicker td {
  text-align:center
}
.ui-datepicker-current-day, .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
  border:none;
  background:none;
  text-align: center;
  text-align:center;
  font-size: 15px;
  background: #bcd0f9;
}
.ui-state-default, .ui-widget-content .ui-state-default{
  color: #454545;
  width: 100%;
  line-height: 34px;
  display: block;
}
.ui-datepicker td a.ui-state-default{
  color: var(--mau1);
}
.ui-state-active, .ui-widget-content .ui-state-active{
  
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight{
  background: #bcd0f9;
}
.ui-datepicker-current-day{
  background: var(--mau1);
}
.ui-datepicker-current-day a, .ui-state-active{
  background:var(--mau1) !important;
  color:white !important;
}
.ui-datepicker td{
  border: 2px solid #fff;
  padding: 0px;
}
.ui-widget-header{
  border:none;
  border-radius: 3px 3px 0px 0px;
}
.ui-datepicker-calendar thead{
  background:#e9e9e9;
}
.ui-datepicker-calendar th{
  padding: 0px 0px 5px 0px;
  font-size: 13px;
}
.ui-datepicker .ui-datepicker-header{
  padding: 10px 5px 10px 0px;
  font-size: 15px;
}
.ui-datepicker-calendar thead, .ui-widget-header{
  background-color: white;
}
.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled{
  background-color: white;
}
.ui-datepicker table{
  margin-bottom: 2px;
  border-radius: 0px 0px 12px 12px;
  overflow: hidden;
}
.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next{
  top: 11px;
  width: unset;
  height: unset;
  cursor: pointer;
  border: none !important;
}
.ui-datepicker-prev span, .ui-datepicker-next span {
  background-image: unset !important;
  font-family: "Flaticon";
  width: 35px;
  height: 28px;
  line-height: 28px;
  font-size: 20px;
  color: #555;
  text-indent: 0px !important;
  text-align: center;
  background-color: #e3e1e0;
  display: block;
  border-radius: 3px;
  position: static !important;
  margin: unset !important;
}
.ui-datepicker-prev span:before{
  content: "\f119";
  font-style: normal;
}
.ui-datepicker-next span:before{
  content: "\f118";
  font-style: normal;
}
.wpsearchsl{
  margin: 0px auto;
  max-width: 75%;
  height: 65px;
  padding-bottom: 0px;
}
.wpsearchsl input{
  border: none;
  height: 22px;
  padding: 0px;
  display: inline-block;
  box-shadow: none;
}
.wpsearchsl input:not([name="from"]){
  height: 33px;
  padding-bottom: 11px !important;
}
.wpsearchsl .textboxdown{
  padding: 0px 20px 0px 0px;
  width: 115px;
  float: left;
  background: url(src/modules/app_sigo/assets/guest/images/arrow-down.png) no-repeat right 8px transparent !important;
}
.wpsearchsl select{
  width: 63px;
  height: 22px;
  padding: 0px;
  border: none;
  float: right;
  box-shadow: none;
}
.pac-logo:after { display:none !important; }
@media (min-width: 768px){
  #slider{
    min-height: 560px;
  }
  .popvitri{
    min-width: 350px;
  }
  .wpinput{
    height: 48px;
  }
  .wpinput.first{
    padding-left: 0px;
  }
  .wpinput.mid{
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
  }
  
}
@media (max-width: 767px){
  .wpsearchsl{
    height: auto;
    padding: 10px;
    margin-bottom: 15px;
  }
  .wpinput{
    width: 100%;
  }
  .wpsearchsl{
    max-width: 100%;
  }
  .btnsearch{
    width: 100%;
    margin-bottom: 10px;
    border-radius: 20px;
  }
  .wpsearch .form-group{
    margin-bottom: 0px;
  }
}

/*** select ***/
.selectdown{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(src/modules/app_sigo/assets/guest/images/arrow-down.png) no-repeat right transparent !important;
  -webkit-appearance: none;
  background-position-x: 244px;
}
.selecthideborder, .formfillter select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url(src/modules/app_sigo/assets/guest/images/arrow-down.png) no-repeat right transparent !important;
  -webkit-appearance: none;
  background-position-x: 244px;
  background: none;
  border: none;
  border-bottom: 2px solid #aaa;
  box-shadow: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  padding-left: 0px;
}
.formfillter input:not([type="range"]){
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  background-position-x: 244px;
  background: none;
  border: none;
  border-bottom: 2px solid #aaa;
  box-shadow: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  padding-left: 0px;
}
.selecthideborder:focus, .formfillter select:focus, .formfillter input:focus{
  box-shadow: none;
}
#content{
  max-width: 100%;
}


/*** dia diem ***/
#diadiem{
  margin-bottom: 50px;
}
.title-home{
  margin-bottom: 15px;
}
.title-home .maintxt{
  font-size: 32px;
  line-height: 45px;
}
.itemdd{
  position: relative;
  color: white;
  display: block;
  overflow: hidden;
  border-radius: 10px;
}
.itemdd:hover{
  color: white;
}
.itemdd img{
  border-radius: 8px;
  display: block;
  transition: all 1s;
}
.itemdd:hover img{
  transform: scale(1.1);
  opacity: 0.9;
}
.itemdd .title{
  position: absolute;
  bottom: 0px;
  padding: 10px;
  font-size: 17px;
  display: block;
  color: white;
}
@media(max-width: 767px){
  .title-home .maintxt{
    font-size: 22px;
    display: block;
    line-height: 33px;
  }
}

/*** uu dai ***/
#uudai{
  margin-bottom: 50px;
}

/*** cho thue + dk ***/
#chothue .maintxt{
  color: white;
}
.bgchothue{
  padding: 20px;
  color: white;
  border-radius: 10px;
  margin-bottom: 50px;
  background-size: auto 100% ;
  position: relative;
}
@media (min-width: 768px){
  .bgchothue{
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

/*** huongdan ***/
#huongdan{
  margin-bottom: 40px;
}

/*** news ***/
#news{
  padding: 30px 0px 10px 0px;
}
.news{
  position: relative;
  margin-bottom: 20px;
}
.news img{
  display: block;
  border-radius: 10px;
  margin-bottom: 15px;
}
.news .title{
  font-size: 17px;
  line-height: 25px;
  display: block;
  color: #000
}

/*** app ***/
#app{
  padding: 40px 0px 20px 0px;
}
#app .item{
  margin-top: 20px;
}
.logoappdownload{
  display: inline-block;
}
.rightlogoapp{
  display: inline-block;
  padding-left: 15px;
  margin-left: 15px;
  border-left: 1px solid #aaa;
}
.rightlogoapp .maintxt{
  font-weight: bold;
  font-size: 22px;
  line-height: 33px;
}
.appqr{
  float: left;
  margin-right: 30px;
  max-height: 130px;
}
@media (max-width: 440px) {
  .rightlogoapp{
    border: none;
    padding-left: 0px;
    margin-left: 0px;
  }
}

/*** banner ***/
.banner{
  display:block;
  overflow:hidden;
  position:relative;
  margin-bottom: 10px;
}
.banner img{
    display: block;
}
.banner:hover::after,.banner:hover::before {
  border:0 double rgba(255,255,255,.7);
  opacity:0;
  visibility:hidden
}
.banner::after,.banner::before {
  border:200px double transparent;
  bottom:0;
  content:"";
  left:0;
  opacity:1;
  position:absolute;
  right:0;
  top:0;
  transition:all .4s cubic-bezier(.94,.85,.1,.62) 0s;
  visibility:visible;
  z-index:1
}


/*** popuplogin ***/
.popupdiv{
  background: #fff;
  transition: all 1s;
  width:100%;
  height:100%;
  position: fixed;
  top:-150%;
  left:0px;
  z-index: 888;
  opacity: 0;
}
.popupdiv.show{
  top:0px;
  opacity: 1;
}
.wplogin{
  position: relative;
  padding: 180px 0px 0px 0px;
}
.closepop{
  font-size: 35px;
  position: absolute;
  right: 0px;
  top:100px;
  z-index: 10;
  color: #222;
}

/***  formuser  ***/
#formuser{
  /* background: transparent url("../images/bg-form-user.jpg") no-repeat top center; tính sau*/ 
  background-size: 100% auto;
  background-attachment: fixed;
  min-height: 100%;
  padding-top: 100px;
}
.wpfsuser{
  padding: 70px 10px 10px 10px;
  background:rgba(255,255,255,1);
}
#formuser .item{
  margin-bottom: 15px;
}
#formuser p{
  margin-bottom: 0px;
}
.logoform{
  margin-bottom: 0px;
  display: inline-block;
  position: absolute;
  top: -50px;
  left: calc( 50% - 50px);
  width: 100px;
  height: 100px;
  line-height: 115px;
  background: #000;
}
.logoform img{
  max-width:90px;
  max-height: 90px;
  filter: brightness(0) invert(1);
}
.title-form{
  margin-bottom: 10px;
  font-weight: 900;
  padding: 0px 10px;
  position: relative;
  text-align:center;
}
.title-form h4{
  font-size: 25px;
  line-height: 18px;
  font-weight: 900;
  display: inline-block;
  padding: 0px 10px;
  margin-bottom: 10px;
  background: #fff;
  position: relative;
  z-index: 1;
}
.title-form::before {
  background: #ccd6dd;
  content: '';
  display: block;
  height: 1px;
  position: relative;
  -webkit-transform: translateY(12px);
  transform: translateY(12px);
}
#formuser .input-group-addon{
  background: none;
}
#formuser .input-group .form-control{
  border-right:none;
}
.jFiler-input-dragDrop{
  max-width: 100%;
}
.parsley-error {
border-color: #ff5d48 !important;
}
.parsley-errors-list.filled {
display: block;
}
.parsley-errors-list {
  display: none;
  margin: 0;
  padding: 0;
}
.parsley-errors-list > li {
  font-size: 15px;
  list-style: none;
  color: #ff5d48;
  margin-top: 5px;
}
.form-section {
  padding-left: 15px;
  border-left: 2px solid #FF851B;
  display: none;
}
.form-section.current {
  display: inherit;
}
.alert-danger{
  color: #222;
  background: #f8cdd3;
}
.alert-default{
  background: #f0f0f0;
}


/*** doi xe ***/
.thumbnailList{
  max-width: 90px;
  float: left;
  margin-right: 10px;
}
.tab-mem{
  width: 100%;
  margin-bottom: 10px;
}
.tab-mem li{
  list-style: none;
  display: inline-block;
  width: 19%;
  margin-bottom: 10px;
  float: left;
}
.tab-mem a{
  color: #333;
  font-size: 19px;
  display: inline-block;
  margin-bottom: 10px;
  line-height: 30px;
  position: relative;
  text-align: center;
  width: 100%;
  padding-top: 15px;
}
.tab-mem a.first:before{
  display: none;
}
.tab-mem a.active{
  color: var(--mau1);
  font-weight: bold;
}
.tab-mem a:before{
  height: 4px;
  width: 100%;
  position: absolute;
  content: "";
  z-index: 2;
  top: 40px;
  left: -50%;
  background-color: #C0D3ED;
}
.tab-mem .active:before, .tab-mem .passed:before{
  background-color: rgba(39,110,241,1);
}
.tab-mem .active i, .tab-mem .passed i{
  background: linear-gradient(90deg, rgba(39,110,241,1) 0%, rgba(22,89,214,1) 100%);
  color: white;
}
.tab-mem i{
  background: #C0D3ED;
  color: #87B5F5;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  line-height: 56px;
  font-size: 20px;
  margin-bottom: 7px;
  position: relative;
  z-index: 3;
}
.tab-mem .txt{
  color: #222;
  font-size: 13px;
  line-height: 20px;
  text-transform: uppercase;
  display: block;
  font-weight: bold;
}
.tab-mem .num{
  color: #222;
  font-size: 13px;
  line-height: 25px;
  height: 25px;
  width: 25px;
  text-transform: uppercase;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid var(--mau1);
  color: var(--mau1);
  display: block;
  position: absolute;
  left: calc( 50% - 12px );
  top: 0px;
  z-index: 5;
}
.ctmem{
  display: none;
}
.ctmem.active{
  display: block;
}
.titletab{
  font-weight: bold;
  font-size: 15px;
  color: #000;
  line-height: 28px;
  margin-bottom: 6px;
  text-transform: uppercase;
}
.wpcontentaddxe label{
  font-weight: normal;
  margin-bottom: 7px;
}
.wpcontentaddxe label{
  font-weight: normal;
  margin-bottom: 7px;
}
.btnchonhinh{
  display: block;
  border: 2px dashed #eee;
  padding: 40px 15px 40px 15px;
  min-height: 120px;
  color: #555;
  text-align: center;
}
.btnchonhinh i{
  font-size: 35px;
  margin-bottom: 6px;
}
.wptanggiam{
  overflow: hidden;
}
.wptanggiam .wptxt{
  width: calc( 100% - 140px );
  float: left;
}
.wptanggiam .wptxt label{
  margin-bottom: 0px;
}
.wptanggiam .input-group{
  width: 130px;
  float: right;
}
.wpswitch .left{
  width: calc( 100% - 60px );
  float: left;
}
.wpswitch .switch{
  width: 50px;
  float: right;
}
.wptanggiam input{
  text-align: center;
}
.wptanggiam .input-group-addon{
  cursor: pointer;
  color: var(--mau1);
  background-color: transparent;
  font-size: 20px;
}
.dexuat{
  color: #999;
  font-weight: lighter;
}
#calendar {
  width: 100%;
}
.fc-day.off{
  background: #e5e5e5;
  color: #888;

}
.fc-day.off .fc-event{
  background: #f5f5f5;
}
.fc-day.off .fc-title{
  display:none;
}

.fc-event {
  font-size: 1em;
  border-radius: 2px;
  border: none !important;
  padding: 5px !important;
}

.fc-day-grid-event .fc-content {
  color: #000;
}
.fc-button.fc-state-default {
  background: var(--mau1);
  border: none;
  color: #fff;
  margin: 0 3px !important;
  padding: 5px 12px !important;
  text-transform: capitalize;
  height: auto !important;
  box-shadow: none !important;
  border-radius: 3px !important;
}
.fc-toolbar h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  text-transform: uppercase;
}
.fc th.fc-widget-header {
  background: #e6e6e6;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 18px;
  padding: 10px 0px;
}
.fc-unthemed th, .fc-unthemed td, .fc-unthemed thead, .fc-unthemed tbody, .fc-unthemed .fc-divider, .fc-unthemed .fc-row, .fc-unthemed .fc-popover {
  border-color: #eff1f3;
}
.bg-primary {
  background-color: #fff !important;
}
div .fc-event.bg-xanh {
  background: repeating-linear-gradient( -45deg, #e5e5e5, #efefef 5px, #e5e5e5 5px, #efefef 25px ) !important;
  
}
.fc-ltr .fc-basic-view .fc-day-top .fc-day-number{
  font-size: 24px;
  line-height: 45px;
  font-weight: bold;
  margin-left: 5px;
  float: left !important;
  position: absolute;
  z-index: -1;
}
.fc-unthemed td.fc-today{
  background-color: #C1D4F7 !important;
}
.fc-day-grid-event .fc-title{
  color: var(--mau1);
}
.bg-default {
  background-color: #64b0f2 !important;
}
.bg-dark.fc-day-grid-event, .bg-dark.fc-day-grid-event .fc-title{
  color: #999;
  opacity: 0.8;
}
.bg-dark.fc-day-grid-event{
  background: repeating-linear-gradient( -45deg, #e5e5e5, #efefef 5px, #e5e5e5 5px, #efefef 25px ) !important;
}
.fc .fc-toolbar>*>*{
  margin: 0px 10px 0px 10px;
}
.fc-scroller{
  height: unset !important;
}
.fc-unthemed .fc-disabled-day{
  background: #fff;
}
.fc-basic-view .fc-body .fc-row{
  min-height: 76px !important;
  height: auto !important;
}
.fc-past{
  color: #aaa;
}
.fc-future{
  color: #000;
  opacity: 1 !important;
}
div .fc-event, .fc-event-dot{
  background: transparent !important;
  padding-top: 45px !important;
}
.fc-title{
  font-size: 15px;
}
.fc-state-disabled{
  background-color: #eee !important;
  color: #888 !important;
}
@media ( min-width: 768px ){
  
}

/*** search xe ***/
.truot{
  position: fixed;
  z-index: 444;
  width: 100%;
  top: 0px;
  left: 0px;
}
#wpndsearch #diemdi{
  padding-right: 25px;
  box-shadow: none;
}
#diemdi:focus{
  border: none;
}
.removeinput{
  position: absolute;
  top: 4px;
  right: 6px;
  z-index: 10;
  color: #999;
  font-size: 10px;
  font-size: 20px;
  line-height: 15px;
  display: none;
}
.title-page{
  font-size: 30px;
  font-weight: bold;
  line-height: 40px;
}
.wpheadersearch{
  box-shadow: 0px 0px 8px #aaa;
}
.ndsearch{
  line-height: 30px;
  padding: 10px 0px 0px 0px;
  transition: all 1s;
  width: calc( 100% - 305px );
  float: left;
  font-size: 14px;
}
.ndsearch [class*="col-"]{
  padding-left: 5px;
  padding-right: 5px;
}
.ndsearch .nice-select{
  height: 30px;
}
.ndsearch .nice-select .current{
  font-size: 14px;
}
.ndsearch.truot{
  box-shadow: 0px 0px 3px #aaa;
}
.ndsngay .fa{
  padding-left:5px;
}
.ndsearch .item{
  margin-bottom: 10px;
}
.ndsearch input{
  display: inline;
  background: none;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  padding: 0px 0px 5px 0px;
  height: 30px;
  line-height: 30px;
}
.ndsearch #diemdi{
  max-width: calc( 100% - 65px );
}
.ndsearch .form-control:focus{
  box-shadow: none;
  moz-box-shadow: none;
  -webkit-box-shadow: none;
}
.ndsearch span{
  line-height: 30px;
  color: var(--mau1);
}
.ndsearch .date{
  width: 90px !important;
}
.ndsearch select{
  display: inline;
  border: none;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  min-width: 90px;
  padding: 0px 10px;
  width: auto;
  height: 30px;
  padding-bottom: 5px;
  float: right;
}
.ndsearch option, option:not(:checked) { 
  color:#000;
}
.guest #wpfillter .dropdown-menu{
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0px 2px 5px #999;
  min-width: 200px;
}
#wpfillter{
  padding: 15px 0px 5px 0px;
  margin-bottom: 20px;
}
#wpfillter .btn{
  margin-bottom: 10px;
}
.ndxe{
  padding: 10px;
}
.xe{
  margin-bottom:20px;
  position: relative;
  border-radius: 15px;
  box-shadow: 0px 0px 7px #aaa;
  overflow: hidden;
  padding-bottom: 10px;
}
.sticker{
  position: absolute;
  top: 10px;
  right: 10px;
  background: rgba(65,104,245,0.7);
  padding: 3px 15px;
  border-radius: 20px;
  color: white;
}
.text-giam{
  font-size: 15px;
  color: #777;
  text-decoration: line-through;
}
.xe img{
  display: block;
}
.xe h3.title{
  font-size: 20px;
  line-height: 30px;
  height:30px;
  overflow:hidden;
  font-weight:bold;
  margin:0px;
  color:#333;
}
.xe .tt{
  padding-bottom: 10px;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom:10px;
  font-weight: 300;;
}
.xe .info{
  font-weight: 300;;
}
.xe .price .text-danger{
  font-weight: bold;
}
.xe .giaoxe{
  float:right;
  color: var(--mau1);
}
.xe .giamgia{
  float:right;
}
.xe .kcach{
  float:right;
  margin-right:5px;
}
.xe .info .fa{
  color: var(--mau1)
}
@media ( min-width: 768px ){
  .nvasearch{
    border-bottom: 1px solid #e5e5e5;
  }
}

/*** qickview ***/
.quatrinh{
  border-radius: 15px;
  box-shadow: 0px 0px 6px #aaa;
  margin-bottom: 20px;
  padding: 17px;
}
.wpbtnquick{
  text-align: center;
}
.wpbtnquick a{
  width: 49%;
}
.wpbtnquick .btn-block{
  width: 100%;
}
.wpcountdown{
  padding-top: 20px;
}
.countdown{
  font-size: 30px;
  line-height: 40px;
  color: #111;
}
.tab-mem .txt p{
  font-weight: lighter;
}
.wpttdx{
  border-radius: 15px;
  border: 1px solid #e5e5e5;
}
.wpttdx .line1{
  border-bottom: 1px solid #e5e5e5;
  padding: 3px 15px;
}
.wpttdx .line1{
  padding: 6px 20px;
}
.wpttdx .line2{
  border-top: 1px solid #e5e5e5;
  overflow: hidden;
  position: relative;
}
.wpttdx .line2 .item{
  float: left;
  width: 50%;
  padding: 13px 20px;
}
.wpttdx .line2 b{
  margin-bottom: 5px;
  display: block;
}
.wpttdx .line2:before, .wpttdx .line2:after{
  content: "";
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: var(--mau1);
  width: 100px;
  height: 4px;
}
.wpttdx .line2:after{
  background-color: #47AA31;
  left: calc( 50% + 20px );
}
.wpttdx .line2 .item.xl{
  border-left: 1px solid #e5e5e5;
}
.wpttdx .ngay{
  font-size: 25px;
  color: #111;
}
.wpttdx .line3{
  border-top: 1px solid #e5e5e5;
  padding: 13px 20px;
}
.member .detailxe .text-right, .member .detailxe .sticker{
  display: none;
}
.member .detailxe .xe{
  height: auto;
  padding-bottom: 0px;
}
.member .detailxe .xe .tt{
  border: none;
}
.muctieuthunl.dien .xang, .muctieuthunl.xang .dien{
  display: none;
}
@media ( min-width: 768px ){
  .wpbtnquick{
    min-height: 175px;
  }
  .wpbtnquick::before{
    content: "";
    width: 1px;
    height: 100%;
    background-color: #e5e5e5;
    position: absolute;
    left: 0px;
    top: 0px;
  }
}

.detailxe .col-sm-6 [class*="flaticon-"]{
  font-size: 22px;
  min-width: 30px;
  display: inline-block;
  vertical-align: middle;
}
.ndcdetail{
  padding: 0px;
  background: #fff;
}
.ndcdetail .item{
  padding-top: 0px;
  padding-bottom: 10px;
}
.ndcdetail h1{
  margin-bottom: 5px;
}
.wpviewmore .content{
  max-height: 75px;
  overflow: hidden;
  margin-bottom: 8px;
}
.wpviewmore.show .content{
  max-height: unset;
}
.viewmore{
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
  color: #555;
  border: 1px solid #555;
}
[data-toggle="popover"]{
  cursor: pointer;
}
.giacu{
  color: #777;
  font-size: 15px;
}
.wpttsb{
  border: 1px solid #eee;
}
.wpttsb input{
  width: 70%;
  float: left;
  border: none;
  box-shadow: none;
}
.wpttsb .nice-select{
  clear: none;
  border: none;
  border-radius: 0px;
  border-left: 1px solid #eee !important;
  width: 30%;
}
.wpvitrigiaoxe{
  position: relative;
}
.itemkm{
  margin-bottom: 30px;
  border-radius: 5px;
  /* box-shadow: 0px 0px 4px #aaa; */
  position: relative;
}
.wrap-item{
  display:flex;
  align-items: stretch;
}
.itemkm .name{
  min-width: 140px;
  display: inline-block;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 5px;
  font-weight: 500;
  background-color: #E2ECFF;
  color: #096CB8;
  margin-right: 4px;
}
.itemkm .dgkm{
  color: #111;
  font-weight: 600;
}
.itemkm .size13px{
  color:var(--den);
  font-weight: 300;
}
.itemkm  .wrap-image{
  /* height: 100%; */
  align-self: stretch;
  display: flex;
  align-items: center;
  min-height: 120px;
  padding: 10px;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
}
.itemkm .nd{
  color:var(--den);
  padding: 10px;
  padding-left: 10px;
  margin-left: -1px;
  border: 1px solid #DCDCDC;
  border-radius: 10px;
  flex: 1;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.itemkm .img{
  border-radius: 5px;  
  min-width:50px;
  /* min-height: 100%; */
  overflow: hidden;
  float: left;
}
.itemkm .img img{
  vertical-align: middle;
  min-width: 100%;
  width: 125px;
  height: 100%;
  /* filter: brightness(0) invert(1); */
}
.itemkm .expiry-date{
  font-size: 14px;
}
.itemkm .btn-condition{
  font-size: 14px;
  position: unset!important;
  color: #000;
  font-weight: bold;
  text-decoration: underline!important;
  outline: none!important;
  border: none!important;
}
.wrap-condition{
  width: 100%;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  margin-top: -1px;
  padding: 16px 30px;
  color: var(--den);
  font-size: 14px;
}
.wrap-row-condition {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.label-condition{
  min-width: 100px;
}
.value-condition{
  font-weight: bold;
}
.wrap-condition .copyma{
  color: var(--mau1);
  font-size: 25px;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}
.faitemkm{
  background: var(--mau1);
  color: white;
  font-size: 25px;
  line-height: 50px;
  text-align: center;
  float: left;
  margin: 0px 10px 0px 0px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}
.itemkm p{
  color: #777;
  line-height: 20px;
}
.itemkm a{
  color: #777;
}
.detailkm{
  text-align: center;
  display: none;
  background-color: #f5f5f5;
}
.detailkm.active{
  display: none;
}
.detailkm .ngay{
  color: var(--mau1);
}
.detailkm .faitemkm{
  margin: 0px auto 10px;
  float: none;
}
.toggledetail{
  /* position: absolute; */
  /* top: 10px;
  right: 10px; */
  min-width:28px;
  min-height:28px;
  background: var(--den);
  border-radius: 50%;
  display:flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  color: #fff;
  & i{
    margin-top: 4px;
  }
}

.item-select{
  min-width:28px;
  min-height:28px;
  border-radius: 50%;
  border:1px solid #797878;
}
.item-select.selected{
  border: 6px solid var(--den);
}

.popkm{
  margin-left: -14px;
  margin-right: -14px;
  color: #888;
}
.popkm .item{
  height: 50px;
  line-height: 50px;
  padding-left: 25px;
  padding-right: 25px;
}
.popkm .content{
  padding-left: 25px;
  padding-right: 25px;
}
.popkm .content ul{
  margin-left: 0px;
}
.popkm .copyma{
  color: var(--mau1);
  font-size: 25px;
  vertical-align: middle;
  margin-left: 10px;
  cursor: pointer;
}
.popkm .ma{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #111;
}
.popkm .value{
  color: #111;
}
.label50pc{
  display: inline-block;
  width: 50%;
}
.btnremovecoupon{
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 14px;
  border: 1px solid red;
  color: red;
  text-align: center;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -moz-border-radius: 50%;
  font-size: 15px;
}
.btnremovecoupon:hover, .btnremovecoupon:focus{
  color: white;
  background-color: red;
}
.divitem{
  margin-bottom: 8px;
}
.devitem-line{
  margin-top: 22px;
  &::before{
    content:"";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #DFDFDF;
    margin-right: calc(var(--bs-gutter-x)* .5);
    margin-left: calc(var(--bs-gutter-x)* .5);
    margin-bottom: 22px;
  }
}
.devitem-line.margin-top-large{
  &::before{
    margin-bottom: 40px;
  }
}
.btn-show-more{
  margin-top:10px;
  border-radius:5px;
  border-color: #868181;
  padding-left: 22px;
  padding-right: 22px;
  font-weight: bold;
  &:hover,&:focus,&:active{
    background-color: #fff!important;
    color:#000!important;
    border-color: #868181!important;
  }
}
.divitem .col-sm-6{
  line-height: 30px;
}
.detailxe .title h3{
  margin:0px;
}
.detailxe .close{
  font-size: 25px;
  position: absolute;
  top: 5px;
  right: 10px;
  color: white;
  opacity: 1;
}
.title-xanh{
  font-size: 17px;
  font-weight:bold;
  margin-bottom: 16px;
  color: #222;
}
.text-note{
  color: #888;
}
.text-grey-wrap{
  color: #787878;
}
.hr10px{
  margin-top: 10px;
  margin-bottom: 10px;
}
.ndsearch.detail .back{
  line-height: 45px;
  text-align:right;
  line-height: 45px;
}
.ui-datepicker{
  z-index:9999 !important;
  font-size: 17px !important;
}
.showimportant{
  display: block !important;
}
.title-md-center{
  font-size:19px;
  text-transform: uppercase;
  margin-bottom:15px;
  font-weight:bold;
  line-height:30px;
}
.avartar{
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  margin-bottom:0px;
}
#bookingmodal{
  color: #555;
}
.ttcx{
  /* margin-bottom: 15px; */
  position: relative;
  /* padding-bottom: 6px; */
}
.ttcx a{
  color: #222;
}
.ttcx img{
  width:40px;
  height:40px;
  object-fit: cover;
  float: left;
  margin: 0px 10px 0px 0px;
}
.ttcx .name{
  text-transform: capitalize;
}
.ttcx h2{
  font-size: 17px;
  line-height: 25px;
  margin-bottom: 0px;
  font-weight: bold;
  color: #111;
}
#bookingmodal .imgxe{
  border-radius: 5px;
}
#bookingmodal .name{
  line-height: 50px;
  font-size: 13px;
}
#bookingmodal .ttcx img{
  max-width: 50px;
}
.xhsao{
  position: absolute;
  padding: 0px 4px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 5px #aaa;
  left: 10px;
  bottom: 0px;
  z-index: 1;
}
.xhsao i{
  font-size: 13px;
}
.dg{
  padding:0px 0px 20px 0px;
}
.dg .avartar{
  border-radius:50%;
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  max-height:60px;
  float:left;
  margin:0px 10px 0px 0px;
  object-fit: cover;
}
.dg .name{
  font-weight:bold;
  color: #000;
  font-size:14px;
  line-height:20px;
}
.dg .time{
  font-size:14px;
  color: #000;
  font-weight: 500;
}
.dg .address{
  font-size:14px;
  line-height:20px;
}
.dg  .symbol {
  display: inline-block;
  border-radius: 50%;
  border: 5px double white;
  width: 30px;
  height: 30px;
}
.dg .symbol-empty {
  background-color: #ccc;
}
.vang {
  color:var(--vang);
}
.dg .fa-star, .den {
  color:var(--den);
}
.wphinh{
  position:relative;
  margin-bottom: 25px;
}
.hinhxe{
  width: 100%;
}
.wphinh img{
  display: block;
}
.previousowl {
  font-size: 17px;
  color: white;
  padding: 7px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background: #444;
  color: #fff;
  position: absolute;
  top: calc(50% - 15px);
  left: 0px;
  height: 30px;
  width: 30px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 1s;
  z-index: 10;
}
.nextowl {
  font-size: 18px;
  color: white;
  padding: 7px;
  border-radius: 100%;
  -moz-border-radius: 100%;
  -webkit-border-radius: 100%;
  background: #444;
  color: #fff;
  position: absolute;
  top: calc(50% - 15px);
  right: 0px;
  height: 30px;
  width: 30px;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  transition: all 1s;
  z-index: 10;
}
.owl-dots{
  position: absolute;
  bottom:10px;
  right:10px;
  z-index: 9999 !important;
}
.owl-dot{
  height:10px;
  width:10px;
  right:10px;
  border-radius:50%;
  display: inline-block;
  margin-right:10px;
  border:1px solid #f0f0f0;
  background:#fff;
}
.owl-dot.active{
  background:var(--mau1);
}
.infosing{
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 8px #aaa;
}
.txtgia{
  font-size: 20px;
  color: var(--den);
  & .main{
    font-size: 24px;
    font-weight: bold;
  }
}
#menutt{
  box-shadow: 0px 0px 6px #aaa;
  z-index: -2;
  padding: 15px 0px;
  opacity: 0;
  position: fixed;
  top: -70px;
  left: 0px;
  transition: all 1s;
  background-color: white;
}
#menutt.truot{
  opacity: 1;
  z-index: 11;
  top: 0px;
}
#menutt li{
  display: inline-block;
  margin-right: 20px;
}
#menutt li a{
  padding: 10px 0px;
  display: inline-block;
  color: #222;
  position: relative;
}
#menutt li .active:before{
  content: "";
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -15px;
  left: 0px;
  background-color: var(--mau1);
}
#menutt .btn{
  float: right;
  margin-left: 15px;
}
#menutt .wpbtn{
  font-size: 13px;
  min-width: 300px;
  text-align: right;
  line-height: 20px;
}
#menutt .total{
  font-size: 15px;
}
.wpmenutt{
  height: 0px;
}
@media (min-width: 768px){
  /* .itemkm{
    padding-right: 100px;
  } */
  .itemkm .btn{
    position: absolute;
    bottom: 15px;
    right: 10px;
  }
  .itemkm .name{
    min-width: 170px;
  }
  .itemkm .img img{
    min-width: 50px;
  }
  /* .itemkm .line{
    height: 100%;
    width: 2px;
    border-left: 1px solid #e0e0e0;
    position: absolute;
    top: 0px;
    left: 120px;
  } */
  .itemkm .nd{
    padding-left:20px;
    width: calc( 100% - 125px );
    float: left;
  }
  .label-condition{
    min-width: 140px;
  }
  .nd .row-expiry{
    display:flex; 
    justify-content: space-between;
    align-items: center;
  }
  .size13px{
    min-height: 40px;
  }
  /* .itemkm .nd:before{
    content: "";
    width: 20px;
    height: 10px;
    background-color: white;
    position: absolute;
    top: -11px;
    left: 112px;
    z-index: 2;
  } */
  /* .itemkm .nd:after{
    content: "";
    width: 20px;
    height: 10px;
    background-color: white;
    position: absolute;
    bottom: -11px;
    left: 112px;
    z-index: 2;
  } */
  /* .itemkm .line:before{
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: -10px;
    left: -11px;
    box-shadow: inset 0px 0px 3px #aaa;
  }
  .itemkm .line:after{
    content: "";
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    bottom: -10px;
    left: -11px;
    box-shadow: inset 0px 0px 3px #aaa;
  } */
}
@media ( max-width: 767px ){
  #menutt ul{
    margin-bottom: 10px;
  }
  #menutt li{
    margin-right: 20px;
    float: left;
  }
  #menutt li .active:before{
    bottom: 0px;
  }
  .viewover.owl-carousel .owl-stage-outer{
    overflow: visible;
  }
  #menutt.truot{
    top: unset;
    bottom: 0px;
  }
}

/*** danh sach ma km ***/
.makm{
  border-radius: 12px;
  box-shadow: 0px 0px 5px #aaaa;
  margin-bottom: 20px;
}
.makm img{
  border-radius: 12px;
  display: block;
}
.makm .nd{
  padding: 10px 20px 15px 20px;
}
.makm .title{
  margin-bottom: 5px;
  color: #111;
  display: block;
  height: 50px;
  overflow: hidden;
}
.makm .alert{
  margin-bottom: 0px;
  padding: 7px 10px;
}
.makm .btn-grad{
  margin-bottom: 0px;
}

/*** thongbao ***/
.thongbao{
  position: relative;
  margin-bottom: 10px;
  color: #555;
  display: block;
}
.thongbao b{
  color: #111;
}
.thongbao .nd{
  padding-top: 20px;
  border-top: 2px solid #e5e5e5;
  float: left;
  position: relative;
  width: calc( 100% - 80px );
}
.thongbao .nd:before{
  content: "";
  position: absolute;
  width: 100px;
  height: 4px;
  background-color: var(--mau1);
  top: -1px;
  left: 0px;
}
.thongbao img{
  float: left;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 20px;
  margin-top: 30px;
}
.thongbao small{
  color: #999;
  font-size: 13px;
}
.thongbao.daxem img{
  opacity: 0.5;
}
.thongbao.daxem, .thongbao.daxem b{
  color: #999;
}
@media ( min-width: 768px ){
  
}


/*** thong tin usser ***/
.tttaixe{
  margin-bottom: 40px;
}
.ttxcthuc{
  padding: 10px 0px 10px 0px;
}
.iconxacminh{
  height: 30px;;
  width: 30px;
  line-height: 35px;
  font-size: 21px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 4px;
  text-align: center;
  margin-right: 5px;
  color: white;
  background: rgb(12,194,246);
  background: linear-gradient(180deg, rgba(12,194,246,1) 0%, rgba(70,103,251,1) 100%);
}
.iconxacminh.chuaxacminh{
  background: #e5e5e5;
  color: #999;
  background: linear-gradient(180deg, rgba(229,229,229,1) 0%, rgba(204,204,204,1) 100%);
}
.tttaixe .ttxt{
  margin-bottom: 10px;
}
.tttaixe .ttxt i{
  float: right;
  font-size: 24px;
  color: var(--mau1);
  vertical-align: middle;
}
.tttaixe .ttxt i.notverify{
  color: #aaa;
}
.tttaixe .ttxt b, .tttaixe .ttxt a{
  float: right;
  color: var(--mau1);
}

/*** member ***/
.ngayalert{
  color: #777;
  font-size: 14px;
}
.memsidebar li a{
  display: block;
  padding: 7px 0px 7px 10px;
}
.memsidebar li.active a, .memsidebar li a:hover{
  background: var(--mau1);
  color: white;
}
.member .breadcrumb{
  border: none;
  padding-bottom: 6px;
  margin: 0px;
}
.line {
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}
.member h1, .member h2, .member h3{
  color: #000;
}

/*** chuyen di ***/
.chuyendi{
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 9px #aaa;
  position: relative;
}
.chuyendi .tenxe{
  line-height: 25px;
}
.datecd{
  font-size: 13px;
  line-height: 20px;
}
.chuyendi img{
  display: inline-block;
}
.chuyendi .trangthai i{
  font-size: 23px;
  line-height: 25px;
  margin-right: 13px;
}
.chuyendi .trangthai{
  padding: 15px 15px;
  background-color: #e5e5e5;
  margin-bottom: 10px;
}
.infonguoi .imgtx{
  max-width: 50px;
  float: right;
  border-radius: 50%;
}
.infonguoi .nguoi{
  overflow: hidden;
  margin-bottom: 10px;
  padding-right: 15px;
  padding-left: 15px;
}
.infonguoi .wpbtn{
  padding-right: 15px;
  padding-left: 15px;
}
.wpavatar{
  width: 140px;
  text-align: right;
  float: right;
  font-size: 13px;
  line-height: 21px;
}
.wpavatar p{
  padding-top: 5px;
}
.wpavatar img{
  margin-left: 10px;
}
.chuyendi .nguoi i{
  color: var(--mau1);
  font-size: 25px;
  line-height: 30px;
  float: left;
  padding: 15px 0px 0px 0px;
}
.wpbtn .btn{
  padding-left: 20px;
  padding-right: 20px;
}
@media ( min-width: 768px ){
  .chuyendi .imgxe{
    margin-right: 15px;
    border-radius: 15px 0px 0px 15px;
    float: left;
    max-height: 250px;
    max-width: 34%;
  }
  .infochuyen{
    width: 35%;
    float: left;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .infonguoi{
    width: 29%;
    padding-top: 15px;
    float: right;
  }
  .infonguoi::before{
    position: absolute;
    top: 0px;
    height: 100%;
    width: 1px;
    content: "";
    background-color: #e5e5e5;
  }
  .infonguoi .nguoi{
    margin-bottom: 20px;
  }
  .chuyendi .trangthai{
    margin-bottom: 20px;
  }
}
@media ( max-width: 767px ){
  .chuyendi img{
    margin-bottom: 15px;
    border-radius: 15px 15px 0px 0px;
  }
}



/*** sidebar ***/
.title-sb{
  font-weight:bold;
  margin-bottom:15px;
  font-size:25px;
  line-height:35px;
}
.yk{
  text-align:center;
  background:#f5f5f5;
  padding:10px;
}
.yk img {
  border-radius: 50%;
  margin-bottom: 10px;
  max-width: 150px;
}
.yk p {
  margin-bottom: 10px;
}
.yk h3{
  font-size:19px;
  font-weight:bold;
  margin-bottom:0px;
}


/*** single ***/
#slider .breadcrumb a{
  color: white;
}
.breadcrumb{
  padding: 15px 0px;
  border-bottom: 1px solid #eee;
  margin-bottom: 15px;
  background-color: transparent;
}
.breadcrumb a{
  color: #333;
}
#lineheader{
  background-color: #c7d2eb;
  box-shadow: inset 0px 0px 5px #999;
  height: 15px;
  position: relative;
}
#lineheader:before{
  content: '';
  background-color: #8ba4dc;
  height: 100%;
  width: 150px;
  position: absolute;
  left: 200px;
}
.title-single{
  font-size:30px;
  font-weight:bold;
  margin-bottom:15px;
  line-height:30px;
}
.navowlsing{
  background: rgba(0, 0, 0, 0.45);
  color: white;
  font-size: 24px;
  width: 35px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  display: block;
}
.chitietxe .owl-next, .chitietxe .owl-prev{
  top: calc( 50% - 32px );
}
.content{
  text-align: justify;
}
.content p{
  margin-bottom:10px;
}
.content *{
  max-width:100%;
}
.content img{
  height:auto !important;
}
.post{
  margin-bottom:15px;
}
.postcol .thumbnail{
  margin-bottom:5px;
}
#share-like{
    margin-bottom: 10px;
}
.title-cat{
  color:#111;
  font-size:15px;
  line-height:25px;
  margin-bottom:0px;
  font-weight:bold;
}
.content ul{
    margin-left: 30px;
    margin-bottom: 10px;
}
.content ul li{
    list-style: none;
    white-space: pre-line;
}
.content ol{
    margin-left: 30px;
    margin-bottom: 10px;
}
.content ol li{
    list-style: decimal;
}
.content table {
    width: 100% !important;
    max-width: 100%;
    margin-bottom: 20px !important;
}
.content table > thead > tr > th {
    vertical-align: bottom;
    border-bottom: 2px solid #ddd;
}
.content table td, table th{
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    border: 1px solid #ddd;
}
.content table tr:nth-of-type(2n+1) {
    background-color: #f9f9f9;
}
.content{
    overflow-x: auto;
}
.modal{
  overflow-x: hidden;
  overflow-y: auto;
}
.sobaohiem{
  background: var(--mau1);
  color: white;
  display: inline-block;
  padding: 1px 10px;
}
.mau1{
  color: var(--mau1);
}
.post .xam{
  color: #999;
  font-size: 13px;
}
@media (min-width: 768px){
  .temgioithieu{
    padding-top: 70px;
  }
  .temgioithieu .line{
    display: none;
  }
  .temgioithieu .logo{
    filter: none;
  }
  .temgioithieu .navbar-nav > li > a{
    color: #222;
  }
  .temgioithieu .truot .navbar-nav > li > a{
    color: white;
  }
  .truot .logo{
    filter: none;
  }
  .title-cat.lg{
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 15px;
  }
  .ndpostfirst{
    padding-top: 50px;
  }
  .middlecat, .topcat{
    margin-bottom: 20px;
  }
  .middlecat img{
    float: left;
    margin: 0px 10px 0px 0px;
    max-width: 90px;
  }
  .middlecat .title-cat{
    height: auto;
  }
}

/*** sidebar ***/
.thumbsb{
  float:left;
  margin:0px 10px 0px 0px;
}
.postsb .title-sb{
  margin:0px;
  font-size:17px;
  color:#333;
  line-height: 25px;
}
.postsb img{
  width:90px;
}

/*** footer ***/
footer{
  padding: 50px 0px 30px 0px;
  background: #efefef;
  font-size: 14px;
}
footer .item{
  margin-bottom: 20px;
}
footer a{
  color: #333;
}
.infoficon{
  margin-bottom: 10px;
}
.infoficon [class*="flaticon-"]{
  color: var(--mau1);
  font-size: 25px;
  float: left;
  margin-right: 10px;
  width: 40px;
  padding-top: 10px;
}
.infoficon b{
  display: block;
}
.logof{
  max-height:80px;
}
.title-foot{
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 15px;
}
.infof{
  font-size: 13px;
}
.wpdownloadapp .appqr{
  margin-right: 15px;
}
.wpdownloadapp img{
  max-height: 69px;
  max-width: 100px;
}
.lbct img{
  max-height: 35px;
}
.socials li{
  margin-right: 10px;
  margin-bottom: 10px;
  float: left;
}
.socials a{
  display: block;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  line-height: 35px;
  text-align: center;
  font-size: 19px;
  color: white;
  background-color: var(--mau1);
}
.socials img{
  display: inline;
  height: 24px;
  margin-top: 6px;
  filter: brightness(0) invert(1);
}
footer hr{
  border-color: #ccc;
}

/*** spin ***/

.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  padding: 10px 0px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 32px 32px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  margin: -3px 0 0 -3px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 50px;
  left: 50px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 54px;
  left: 45px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 57px;
  left: 39px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 58px;
  left: 32px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 57px;
  left: 25px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 54px;
  left: 19px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 50px;
  left: 14px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 45px;
  left: 10px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.wploading{
  background: rgba(0,0,0,0.65);
  position:fixed;
  top:0px;
  left:0px;
  z-index: 9999;
  text-align:center;
  width:100%;
  height:100%;
}
.wploading .lds-roller{
  position: absolute;
  top: calc(50% - 45px);
  left: calc(50% - 45px);
  z-index: 99999;
}

.form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.form-inline .form-control {
  display: inline-block;
  width: auto;
  vertical-align: middle;
}
.form-inline .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 5px;
}

/*** member ***/
.wpnav .nav{
  margin: 0px;
}
.wpnav .nav a{
  padding: 15px 0px;
  margin-right: 30px;
  text-transform: lowercase;
  font-weight: bold;
  color: #000;
  border: none;
}
.wpnav .nav a:first-letter {
  text-transform: uppercase;
}
.wpnav .nav .active a{
  border-bottom: 2px solid var(--mau1);
  color: var(--mau1);
}
.guest .wpnav li{
  height: unset !important;
}
.wpnav2 .nav li a{
  text-transform: inherit;
  font-weight: 300;
}
.wpnav2 .nav .active a{
  color: #222;
  font-weight: 400;
}
sup{
  font-size: 12px;
}
.member .xe .tt{
  margin-bottom: 0px;
}
.member .xe small{
  font-size: 13px;
  line-height: 20px;
}

/*** taixe ***/
:root{
  --primary: #275efe;
  --handle: #fff;
  --handle-active: #becfff;
  --handle-hover: #e9efff;
  --line: #cdd9ed;
  --text: #99a3b3;
  --stroke: var(--stroke);
  --line-active: var(--primary);
  --handle-border: 2px solid var(--primary);
}
.wprange{
  padding: 0px 10px;
  margin-bottom: 15px;
}
.slider {
  --primary: #275efe;
  --handle: #fff;
  --handle-active: #becfff;
  --handle-hover: #e9efff;
  --handle-border: 2px solid var(--primary);
  --line: #cdd9ed;
  --line-active: var(--primary);
  height: 23px;
  width: 100%;
  position: relative;
  pointer-events: none;
}
.slider .ui-slider-handle {
  --y: 0;
  --background: #fff;
  cursor: -webkit-grab;
  cursor: grab;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  width: 23px;
  height: 23px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  position: absolute;
  outline: none;
  display: block;
  pointer-events: auto;
}
.slider .ui-slider-handle div {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  -webkit-transition: background .4s ease;
  transition: background .4s ease;
  -webkit-transform: translateY(calc(var(--y) * 1px));
          transform: translateY(calc(var(--y) * 1px));
  border: var(--handle-border);
  background: var(--background);
}
.slider .ui-slider-handle:hover {
  --background: var(--handle-hover);
}
.slider .ui-slider-handle:active {
  --background: var(--handle-active);
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.slider svg {
  --stroke: var(--line);
  display: block;
  height: 83px;
}
.slider svg path {
  fill: none;
  stroke: var(--stroke);
  stroke-width: 1;
}
.slider .active, .slider > svg {
  position: absolute;
  top: -30px;
  height: 83px;
}
.slider > svg {
  left: 0;
  width: 100%;
}
.slider .active {
  position: absolute;
  overflow: hidden;
  left: calc(var(--l) * 1px);
  right: calc(var(--r) * 1px);
}
.slider .active svg {
  --stroke: var(--line-active);
  position: relative;
  left: calc(var(--l) * -1px);
  right: calc(var(--r) * -1px);
}
.slider .active svg path {
  stroke-width: 2;
}

.box .values {
  margin: 0;
  font-weight: 500;
  /* color: var(--primary); */
}
.box .values div, .box small div {
  display: inline-block;
  vertical-align: top;
}
.box small {
  color: var(--text);
  display: block;
  margin-top: 8px;
  font-size: 14px;
}

#alertstop{
  max-height: 350px;
}
.wpinfoxu{
  border-radius: 50px;
  box-shadow: 0px 0px 4px #aaa;
  margin: 0px auto 15px;
  min-width: 220px;
  display: inline-block;
  padding: 10px 10px 10px 30px;
  text-align: center;
}
.wpinfoxu .num{
  color: #000;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
}
.wpinfoxu img{
  float: right;
  margin-left: 15px;
  max-width: 65px;
  border-radius: 50%;
}
.wpxu p{
  font-weight: bold;
  font-size: 19px;
  color: var(--mau1);
}
.lsxu.alert-default .soxu{
  color:rgb(67, 169, 58);
}
.lsxu.alert-danger .soxu{
  color:red;
}
.lsxu .time{
  color: #888;
}
.wpgtbb{
  max-width: 500px;
  text-align: center;
  margin: 0px auto 15px;
  color: #000;
}
.wpgtbb .item{
  float: left;
  width: 50%;
}
.wpgtbb .num{
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
}
.wpmagt{
  border-radius: 25px;
  color: white;
  max-width: 500px;
  height: 50px;
  line-height: 46px;
  margin: 0px auto 15px;
  padding: 2px 2px 2px 20px;
  text-align: center;
  background: linear-gradient(90deg, rgba(39,110,241,1) 0%, rgba(22,89,214,1) 100%) ;
}
.wpmagt .wpsku{
  border-radius: 25px;
  color: #111;
  width: 140px;
  background-color: white;
  height: 46px;
  float: right;
}
.wpmagt i{
  font-size: 25px;
  margin-left: 15px;
}

/*** so du ***/
.wpmenutab{
  margin-bottom: 15px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  overflow: hidden;
}
.wpmenutab a{
  height: 52px;
}
.contenttab{
  display: none;
}
.contenttab.active{
  display: block;
}
.boxradius{
  border-radius: 15px;
  box-shadow: 0px 0px 5px #999;
  padding: 12px;
  margin-bottom: 15px;
  min-height: 180px;
  position: relative;
  display: block;
}
.cardbank .bghead{
  background-color: #8E8A8B;
  margin: -12px -12px 0px -12px;
  border-radius: 15px 15px 0px 0px;
  overflow: hidden;
  min-height: 65px;
  position: relative;
}
.addcarrd .wp{
  padding-top: 45px;
  height: 100%;
  width: 100%;
  text-align: center;
  color: #999;
}
.addcarrd .fa{
  font-size: 35px;
}
.cardbank .bghead .txt{
  position: absolute;
  right: 30px;
  bottom: 20px;
  color: #999596;
  font-size: 60px;
  line-height: 46px;
  font-weight: bold;
}
.cardbank .infobank{
  position: relative;
  z-index: 2;
  margin-top: -25px;
}
.namebank{
  font-size: 15px;
  height: 25px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: white;
  font-weight: bold;
  line-height: 25px;
}
.cardbank .icobank{
  padding-top: 6px;
  margin-right: 10px;
  line-height: unset;
  height: unset;
}
.cardbank .icobank .fa{
  border-radius: 5px;
  padding: 10px;
  font-size: 20px;
  background-color: #E5E2E3;
}
.txtxacthuc{
  color: red;
  font-size: 15px;
}
.txtxacthuc.active{
  color: var(--mau1);;
}
.cardbank .btn-default{
  color: var(--mau1);
  background-color: #DFE0E2;
}


/*** buttton ***/
/* https://gradientbuttons.colorion.co/ */
.btn-primary.active, .btn-primary:active, .open>.dropdown-toggle.btn-primary,
.btn-grad, .btn-primary {
  background-color: var(--mau1);
  background-image: linear-gradient(to right, rgba(15,78,190,1) 0%, rgba(39,108,238,1)  51%, rgba(15,78,190,1)  100%)
}
.btn-grad.disable{
  cursor: not-allowed;
  background-image: none;
  background-color: #e5e5e5;
  color: #999;
}
.btn {
  transition: 0.5s;
  background-size: 200% auto;
}
.btn-grad {
  margin: 10px 0px;
  padding: 8px 20px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;            
  box-shadow: 0 0 20px #eee;
  border-radius: 20px;
  display: inline-block;
  border: none;
  font-size: 15px;
}
.btn-grad:hover, .btn:hover {
  background-position: right center; /* change the direction of the change here */
  color: #fff;
  text-decoration: none;
}
.btn-grad .circel, .btn .circel{
  background: rgba(255,255,255,0.2);
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin-left: 5px;
  text-align: center;
  line-height: 20px;
}
.btn-grad .circel.left, .btn .circel.left{
  margin: 0px 5px 0px 0px;
}
.modal-footer .btn-grad{
  margin: 0px;
}


.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 23px;
}
.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider:before {
  position: absolute !important;
  content: "" !important;
  height: 15px !important;
  width: 15px !important;
  left: 4px;
  top: 4px !important;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
  content: "" !important;
}
.thumbpost{
  border-radius: 10px;
  margin-bottom: 15px;
}

/*** responsive ***/
@media (min-width: 1160px) {
  .modal-lg {
    width: 1160px;
  }
}
@media (min-width: 1024px) {
  .xe:not(.taixe){
    height:367px;
  }
  .member .xe{
    height:405px;
  }
  .row60px{
    margin-left:-60px;
    margin-right:-60px;
  }
  .row60px [class^="col-"]{
    padding-left:60px;
    padding-right:60px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .xe:not(.taixe){
    height:347px;
  }
  .member .xe{
    height:367px;
  }
}
@media (min-width: 768px) {
  .hidden-desktop, .navbar-collapse.collapse.hidden-desktop{
    display: none !important;
  }
  header .navbar{
    padding:10px 0px 0px 0px;
  }
  .pull-sm-left {
    float: left !important;
  }
  #scrollbn {
    padding-right: 5px;
  }
  .container .jumbotron{
    padding: 30px;
  }
  .jumbotron h2, .jumbotron h3{
    font-weight:bold;
    margin-bottom:15px;
  }
  footer .last{
    text-align:right
  }
  footer .middle{
    text-align:center;
  }
  .btnpop{
    margin-bottom:10px;
  }
  #why{
    padding-bottom: 60px;
  }
  
}
@media (max-width: 767px) {
  body div .hidden-mobile, .hidden-mobile.collapse.in{
    display: none !important;
  }
  .btnheadermenumb{
    float: right;
    line-height: 20px;
    padding: 3px 8px;
    margin-top: 10px;
  }
  .navbar-collapse.open{
    display: block !important;
    height: unset !important;
  }
  .service-box{
    margin-bottom: 0px;
  }
  .colmenu{
    overflow: hidden;
  }
  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
  .wpnav{
    overflow-x: auto;
  }
  .wpnav .navbar-nav{
    background: transparent;
  }
  .wpnav .nav>li{
    display: inline-block;
  }
  .wpnav .nav a{
    margin-right: 10px;
  }
  .wpnav .nav li:last-child a{
    margin-right: 0px;
  }
  .nav>li>a:focus, .nav>li>a:hover{
    background: none;
  }
  .dropdown-menu{
    padding: 0px;
  }
  .navbar-header a:hover, .navbar-header a:focus{
    color: var(--mau1);
  }
  .menutaixe{
    background: #f5f5f5;
    margin-bottom: 10px;
  }
  body{
    padding-top:46px;
  }
  #slider{
    padding-top: 20px;
  }
  .modal-dialog{
    max-width: 95%;
    padding-top: 40px;
  }
  .navbar{
    position:fixed;
    z-index: 9999;
    top:0px;
    left:0px;
    width:100%;
    box-shadow:0px 0px 3px #888;
  }
  .navbar-header{
    color:white;
    background-color: white;
  }
  .navbar-header .fabtn{
    padding:2px 10px;;
  }
  .navbar-nav > li > a.btn{
    color:white;
    border-radius:0px;
    -moz-border-radius:0px;
    -webkit-border-radius:0px;
    text-align:left;
  }
  .navbar-nav > li > a.btn:hover{
    background:var(--mau2);
  }
  .hidden-mobile{
    display: none !important;
  }
  .btnh{
    margin-top:5px;
    padding: 0px 10px;
    float:right;
    line-height: 40px;
    color:white;
  }
  .btnh.bg{
    background: #f5f5f5;
    color: #000;
    display: inline-block;
  }
  .navbar-brand{
    padding: 5px 0px 5px 10px;
  }
  .navbar-brand img{
    max-height: 30px;
    margin-top:5px;
  }
  .ico-menu{
    margin: 0px 10px 0px 0px;
    color: white;
  }
  .navbar-toggle .icon-bar{
    background-color: var(--mau1);
  }
  nav .navbar-nav > li.active > a{
    background-color: var(--mau1);
    color: white;
    padding-top: 12px; 
    padding-bottom: 12px; 
  }
  /* ANIMATED X */
  .navbar-toggle.x .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transform-origin: 10% 10%;
    -ms-transform-origin: 10% 10%;
    transform-origin: 10% 10%;
  }

  .navbar-toggle.x .icon-bar:nth-of-type(2) {
    opacity: 0;
    filter: alpha(opacity=0);
  }

  .navbar-toggle.x .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transform-origin: 10% 90%;
    -ms-transform-origin: 10% 90%;
    transform-origin: 10% 90%;
  }

  /* ANIMATED X COLLAPSED */
  .navbar-toggle.x.collapsed .icon-bar:nth-of-type(1) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }

  .navbar-toggle.x.collapsed .icon-bar:nth-of-type(2) {
    opacity: 1;
    filter: alpha(opacity=100);
  }

  .navbar-toggle.x.collapsed .icon-bar:nth-of-type(3) {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  /* END ANIMATED X */
  .navbar-collapse{
    border: none;
  }
  .collapse.in {
    display: block !important;
  }
  .navbar-nav{
    position: relative;
    background: #fff;
    overflow-y: auto;
    margin-top: 0px;
    max-height: 400px;
    margin-bottom: 0px;
    z-index: 999;
  }
  .navbar{
    margin-bottom: 0px;
    min-height: 40px;
    overflow: hidden;
  }
  .navbar-nav > li > a, .nav .open>a, .nav .open>a:focus, .nav .open>a:hover, .dropdown-menu>li>a{
    color: #444;
    background-color: white;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  .navbar-nav .open .dropdown-menu>li>a{
    font-size: 17px;
    font-weight: normal;
    padding: 10px 0px 10px 30px;
  }
  .navbar-nav > li:first > a{
    border-top: 0px;
  }
  .navbar-nav > li:last > a{
    border-bottom: 0px;
  }
  .navbar-nav > .current-menu-item > a, .navbar-nav > .current-menu-parent > a{
    background: #00C853;
    color: white;
  }
  #top li {
    margin-left: 0px;
    margin-right: 10px;
  }
  .menumember > a{
    display: none;
  }
  [data-show="menumember"] .menumember .dropdown-menu, [data-show="lilistalert"] .lilistalert .dropdown-menu{
    display: block;
    position:relative;
    padding:0px;
    width:100%;
    font-size:unset;
    background-color:unset;
    box-shadow:unset;
    -moz-box-shadow:unset;
    -webkit-box-shadow:unset;
    border:0px;
  }
  .callus a span {
    display: none;
  }
  .callus {
    padding-right: 0;
    width: 50px;
    height: 50px;
    bottom: 5px;
  }
  .callus i {
    margin: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
  .ppocta-ft-fix {
    left: 67px;
  }
  .postcol .thumbnail{
    float: left;
    margin: 0px 10px 0px 0px;
  }
  .postcol img{
    max-width: 90px;
  }
  .wpheadersearch{
    box-shadow: none;
  }
  .ndsearch.truot{
    top: 45px;
  }
  .ttsearchh{
    padding: 3px 5px;
    background: rgba( 255,255,255,0.2);
    font-size: 13px;
    width: calc( 100% - 120px );
    float: right;
    line-height: 16px;
    margin: 2px 10px 0px 0px;
    position: relative;
    padding-right: 10px;
  }
  .ttsearchh [class*="flaticon"]{
    position: absolute;
    top: 16px;
    right: 5px;
  }
  .ttsearchh .dc{
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 15px;
      margin-bottom: 3px;
  }
  .btnfilltersearch{
    position: fixed;
    bottom: 5px;
    right: 10px;
    z-index: 10;
  }
  #alertstop{
    max-height: 350px;
  }
  .menumember .dropdown-menu>li:last-child>a{
    border-radius: 0px 0px 7px 7px;
  }
  #wpfillter{
    padding: 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 580px) {
  .thumbnailList{
    float:none;
    display:block;
  }
  #slider{
    background-size: auto !important;
  }
  #slider h3{
    font-size: 35px;
    line-height: 50px;
  }
  .search-by-service__container{
    padding: 15px 10px;
  }
  .search-by-service__wrapper .tabs-left, .search-by-service__box{
    width: 100%;
  }
  .service-box{
    display: inline-block;
    border-radius: 5px 5px 0px 0px;
    padding: 10px 20px;
  }
  .modal-dialog{
    width: 95%;
  }
  .modal-body{
    padding: 10px;
  }
}
@media (max-width: 480px) {
  .tentinhxe{
    float: none !important;
    display: block;
  }
  .form-inline input{
    max-width: 137px;
  }

}
@media (max-width: 414px){
  .wpdownloadapp img{
    max-height: 62px;
    max-width: 88px;
    margin-right: 0px;
  }
  .wpdownloadapp .appqr{
    margin-right: 7px;
  }
}